import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import Card from "../components/Card";
import Icon from "../components/Icon";
import Advisory from "../components/advisory";

const Doctors_db = () => {
  const navigate = useNavigate();

  const doctorsList = useCallback(() => {
    navigate(`/doctors-list`);
  }, [navigate]);

  const prescriptionList = useCallback(() => {
    navigate(`/prescriptions-list`);
  }, [navigate]);

  return (
    <div className="row mb-2">
      <div className="col-6" onClick={() => doctorsList()}>
        <Card image="assets/NewMiniapp/docs.png" title="Instant Doctor" />
      </div>
      <div className="col-6">
        <div className="d-flex flex-column">
          <div>
            <Link
              to="/insurancescreen/agri/1002"
              style={{ textDecoration: "none", color: "black" }}>
              <Advisory
                image="assets/NewMiniapp/agri.png"
                title="Agri Experts"
              />
            </Link>
          </div>
          <Link
            to="/insurancescreen/vets/1001"
            style={{ textDecoration: "none", color: "black" }}>
            <Advisory image="assets/NewMiniapp/Vet.png" title="Vet Doctors" />
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Doctors_db;
