// src/components/Historycard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../src/style.css';

const Bike_carinsurance = ({ image, title, link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <div className="custom-card mb-2" onClick={handleClick} style={{padding:'6px'}}>
      <div className="row align-items-center">
      <div className="col-12">
          <div className="text-center card-body p-2">
            <p className="card-title" style={{fontSize:'10px'}}>{title}</p>
          </div>
        </div>
        <div className="col-12">
          <img src={image} className="card-img-top" alt={title}/>
        </div>

      </div>
    </div>
  
  );
};

export default Bike_carinsurance;
