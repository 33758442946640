class bike_packages {
  constructor() {
    this.items = [
      {
        id: 175,
        name: "Bike Insurance",
        badge: "Monthly",
        coverage: "50,000",
        price: "150",
        bgColorClass: "bg-info",
        description: `<p><strong>Hassle-Free Bike Insurance:</strong> Get covered quickly with our simple online registration process.</p>
<ul>
<li><strong>Total Insurance Cover:</strong> PKR 50,000</li>
<li><strong>Accidental Bike Repair:</strong> Up to PKR 15,000</li>
<li><strong>Accidental Hospitalization:</strong> Up to PKR 35,000</li>
<li><strong>RSA Coverage:</strong> Available for Rawalpindi/Islamabad</li>
</ul>
<p><strong>Swift Claims, Superior Support:</strong> Our team ensures a smooth and speedy claims experience.</p>`,
        type: "insurance",
      },
      {
        id: 176,
        name: "Bike Insurance",
        badge: "Annual",
        coverage: "100,000",
        price: "1500",
        bgColorClass: "bg-warning",
        description: `<p><strong>Hassle-Free Bike Insurance:</strong> Get covered quickly with our simple online registration process.</p>
<ul>
<li><strong>Total Insurance Cover:</strong> PKR 75,000</li>
<li><strong>Accidental Bike Repair:</strong> Up to PKR 25,000</li>
<li><strong>Accidental Hospitalization:</strong> Up to PKR 50,000</li>
<li><strong>RSA Coverage:</strong> Available for Rawalpindi / Islamabad</li>
</ul>
<p><strong>Swift Claims, Superior Support:</strong> Our team ensures a smooth and speedy claims experience.</p>`,
        type: "insurance",
      },
      // {
      //   id: 25,
      //   name: "Road Side Assistance",
      //   badge: "Monthly",
      //   coverage: "",
      //   price:"100",
      //   bgColorClass: "bg-info",
      //   description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //     `,
      //     type: "rsa",
      // },
      // {
      //   id: 26,
      //   name: "Road Side Assistance",
      //   badge: "Annual",
      //   coverage: "",
      //   price:"1000",
      //   bgColorClass: "bg-warning",
      //   description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //     `,
      //     type: "rsa",
      // },
    ];

    this.terms = `
  <p><strong>Coverage Limitations</strong></p>
  <ul>
    <li>Fire, loss, and theft are excluded from coverage.</li>
    <li>Coverage is applicable only in the event of a Road Traffic Accident (RTA), as defined by the terms of this policy.</li>
  </ul>
  <p><strong>Eligibility Criteria</strong></p>
  <ul>
    <li>The policyholder must be a licensed holder.</li>
    <li>The policyholder must be the owner of the insured car.</li>
    <li>Age of the policyholder must be between 18 and 59 years.</li>
    <li>The insured car must be used solely for domestic or transportation purposes, excluding involvement in racing or other activities.</li>
  </ul>
  <p><strong>Exclusions</strong></p>
  <ul>
    <li>Excluded perils include war, strikes, riots, civil commotion, and any natural or man-made disasters leading to a declaration of a state of emergency on a local or national level.</li>
    <li>Coverage does not extend to preexisting conditions (accidents/damages/hospitalization) of the insured car and insurer.</li>
    <li>Services related to vehicle modification or customization are not covered.</li>
    <li>Routine maintenance services such as oil changes, tire rotations, or battery replacements are excluded.</li>
    <li>Vehicles used for commercial purposes, such as Bykea or Careem, etc., are not covered unless specifically included in the policy.</li>
    <li>Services performed at repair shops or dealerships, including repairs or maintenance, are excluded unless specifically covered in the policy.</li>
  </ul>
  <p><strong>Claims Process</strong></p>
  <ul>
    <li>All claims must be filed via Whatsapp within a maximum of 15 days from the date of the Road Traffic Accident (RTA).</li>
    <li>The following documents are required to raise a claim:</li>
    <ul>
      1. Registration Book of the insured car.<br />
      2. Police report documenting the RTA.<br />
      3. Valid license of the policyholder.<br />
      4. National Identity Card (CNIC) of the policyholder.<br />
      5. Repair receipts where applicable.<br />
      6. Hospital admission, bills, discharge slip, and emergency bills in case of RTA-related injuries.</li>
    </ul>
    <li>Upon completion of the required documentation, claims will be processed and paid within 15 days.</li>
  </ul>
`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default bike_packages;
