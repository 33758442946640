import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import Header_packages from "../components/Header_packages";
import "./transaction-list.css";

const TransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const openId = userData?.openId || null;

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          `https://insuranceservice.webddocsystems.com/api/MiniApp/GetMyActivePackages?OpenId=${openId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization:
                "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTransactions(data.myPackage || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [openId]);

  const openModal = (transaction) => {
    setSelectedTransaction(transaction);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Container className="my-4">
      <Header_packages title="Transactions" />
      <div style={{ paddingTop: "50px" }}></div>

      <Row style={{ marginBottom: "120px" }}>
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : transactions.length === 0 ? (
          <p className="text-center">No transactions available.</p>
        ) : (
          transactions.map((transaction, index) => (
            <Col key={index} lg={4} md={6} className="mb-4">
              <Card
                className="h-100"
                onClick={() => openModal(transaction)}
                style={{
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                <Card.Body>
                  <Card.Text>
                    <Row>
                      <Col lg={4} md={4} sm={4} xs={4}>
                        <p>
                          <strong>Product:</strong> {transaction.productName}
                        </p>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={6}>
                        <p>
                          <strong>Type:</strong> {transaction.productType}
                        </p>
                        <p>
                          <strong>Duration:</strong> {transaction.duration}
                        </p>
                        <p>
                          <strong>Price:</strong> {transaction.productPrice}
                        </p>
                      </Col>
                      <Col
                        className="d-flex justify-content-end align-items-center"
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                      >
                        <div className="parent-frame-set">
                          <div className="parent-frame-set-child"></div>
                          <img
                            className="parent-frame-set-item"
                            loading="eager"
                            alt=""
                            src="/arrow-2.svg"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>

      {/* Transaction Modal */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTransaction && (
            <Card
              className="h-100"
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={6} className="mb-3">
                      <p>
                        <strong>Product Type:</strong>
                      </p>
                       <p>{selectedTransaction.productType}</p> 
                      <p>
                        <strong>Product Name:</strong>
                      </p>
                      <p>{selectedTransaction.productName}</p> 
                      <p>
                        <strong>Duration:</strong>
                      </p>
                       <p>{selectedTransaction.duration}</p>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6} className="mb-3">
                      <p>
                        <strong>Price:</strong>
                      </p>
                       <p>{selectedTransaction.productPrice}</p> 
                      <p>
                        <strong>Activate Date:</strong>
                      </p>
                       <p>{selectedTransaction.activateDate}</p> 
                      <p>
                        <strong>Expiry Date:</strong>
                      </p>
                       <p>{selectedTransaction.expiryDate}</p> 
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default TransactionList;
