import Header_packages from "../components/Header_packages";
import DoctorListComponent from "../components/doctor-list-component";
import { Container } from "react-bootstrap";

const DoctorsList = () => (
  <>
 <Header_packages title="Doctors"/>
    <Container className="mt-2 pt-3">
      <DoctorListComponent />
    </Container>
  </>
);

export default DoctorsList;
