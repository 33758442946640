// src/components/Historycard.js
import React from "react";
import "../../src/style.css";

const Historycard = ({ image, title }) => {
  return (
    <div className="custom-card mb-2  p-2">
      <div className="row align-items-center text-center">
      <div className="col-12">
          <div className="card-body">
            <p className="card-title" style={{fontSize:'10px'}}>{title}</p>
          </div>
        </div>
        <div className="col-12">
          <img
            src={image}
            className="card-img-top"
            alt={title}
           style={{width:"50%"}}
          />
        </div>
       
      </div>
    </div>
  );
};

export default Historycard;
