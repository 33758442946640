import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FrameComponent1 from "../components/doctore-experience-frame";
import Header_packages from "../components/Header_packages";
// import DButton from "../components/dynamic-button";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLoading } from "../contexts/LoadingContext";
import "./doctor-profile.css";

const DoctorProfile = () => {
  const [doctorProfile, setDoctorProfile] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { isLoading, setIsLoading } = useLoading();
  const { id } = useParams();
  useEffect(() => {
    const fetchDoctorProfile = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://testingnew.webddocsystems.com/public/api/v1/doctor/view?user_id=${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch doctor profile");
        }
        const data = await response.json();
        if (data.statusCode === 1 && data.payLoad) {
          setDoctorProfile(data.payLoad);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching doctor profile:", error);
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    };

    fetchDoctorProfile();
  }, [id]);

  const onVideoCallClick = useCallback(() => {
    const email = doctorProfile.Email;
    navigate(`/video-call`, { state: { email } });
  }, [navigate, doctorProfile]);

  return (
    <>
      <Container fluid>
        <Header_packages title="Doctor Profile" />
        <div style={{ paddingTop: "50px" }}>
          <div>
            <Row
              style={{
                marginBottom: "100px",
                // marginTop: "20px",
                // margin: "0px",
              }}
            >
              <Col xs={12} sm={12} md={12} lg={12}>
                <div>
                  {doctorProfile && (
                    <>
                      <Col xs={12} sm={12} md={4} lg={4}>
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={doctorProfile.ImgLink}
                            style={{
                              width: "130px",
                              height: "130px",
                              borderRadius: "50%",
                              "@media (maxWidth: 767px)": {
                                width: "60%",
                              },
                            }}
                          />
                          <div className="mt-2">
                            <p className="doctor-profile-name">
                              {doctorProfile.FirstName} {doctorProfile.LastName}
                            </p>
                            <div className="row align-items-center">
                              <div className="col d-flex justify-content-center">
                                <img
                                  style={{
                                    marginRight: "10px",
                                    marginBottom: "15px",
                                  }}
                                  loading="eager"
                                  alt=""
                                  src="/star-81.svg"
                                />
                                <p className="custom-ratings">
                                  {doctorProfile.RatingCount} Positive Patients
                                  Reviews
                                </p>
                              </div>
                            </div>
                          </div>

                          <FrameComponent1 data={doctorProfile} />
                        </div>
                      </Col>

                      <Col xs={12} sm={12} md={8} lg={8} className="mt-4 p-2">
                        <p className="doctor-headings">About</p>
                        <p
                          className="custom-text-class"
                          style={{ textAlign: "justify" }}
                        >
                          {doctorProfile.DetailedInformation}
                        </p>
                        <p className="doctor-headings">Experience</p>
                        <p
                          className="custom-text-class"
                          style={{ textAlign: "justify" }}
                        >
                          {doctorProfile.FirstName} {doctorProfile.LastName} has
                          over {doctorProfile.Experience} of experience in
                          the field.
                        </p>
                        <p className="doctor-headings">Qualification</p>
                        <p className="custom-text-class">
                          {doctorProfile.Allqualifications}.
                        </p>
                      </Col>
                    </>
                  )}
                </div>
              </Col>
              <div className="d-flex justify-content-center">
                <Button
                  variant="outline-info"
                  style={{
                    fontWeight: "600",
                    height: "50px",
                    color: "#57928E",
                    minWidth: "40%",
                    borderRadius: "40px",
                    border: 0,
                    boxShadow: "0 0 6.3px 1px rgba(0, 0, 0, 0.25)",
                  }}
                  type="button"
                  onClick={() => onVideoCallClick()}
                >
                  Video Consultation
                </Button>
              </div>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default DoctorProfile;
