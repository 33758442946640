import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card} from "react-bootstrap";
import Header_packages from "../components/Header_packages";
import { useLoading } from "../contexts/LoadingContext";
import "./prescriptions-list.css";

const PrescriptionsList = () => {
  const [prescriptionListData, setPrescriptionListData] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();
  const session = JSON.parse(sessionStorage.getItem("user"));
  let sessionUserId = session?.applicationUserId || null;

  useEffect(() => {
    if (sessionUserId) {
      const fetchData = async () => {
        setLoadingMore(true);
        setIsLoading(true);
        try {
          const response = await fetch(
            // `https://testingnew.webddocsystems.com/public/api/v1/prescription/list?patientId=${sessionUserId}&page=${page}&perPage=10`
            `https://testingnew.webddocsystems.com/public/api/v1/prescription/list?patientId=${sessionUserId}&page=1&perPage=20`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (data.payLoad.length === 0) {
            setPrescriptionListData(data.payLoad)
            // setAllDataLoaded(true);
            // return;
          } 
          setPrescriptionListData(data.payLoad)
          // else {
          //   setPrescriptionListData((prevTransactions) => [
          //     ...prevTransactions,
          //     ...data.payLoad,
          //   ]);
          //   setPage((prevPage) => prevPage + 1);
          // }
        } catch (error) {
          console.error("Error fetching transactions:", error);
        } finally {
          setLoadingMore(false);
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [sessionUserId, page, setIsLoading]);

  const onAudioVideoCallClick = useCallback(
    (id) => {
      navigate(`/prescription/${id}`);
    },
    [navigate]
  );

  // const handleScroll = useCallback(() => {
  //   if (
  //     window.innerHeight + window.scrollY >= document.body.scrollHeight &&
  //     !loadingMore &&
  //     !allDataLoaded
  //   ) {
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // }, [loadingMore, allDataLoaded]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [handleScroll]);

  return (
    <Container fluid>
      <Header_packages title="Prescriptions"/>
        <Row className="mt-4 p-2" style={{ marginBottom: "100px" }}>
          {prescriptionListData ? (
            <>
              {prescriptionListData.map((value) => (
                <Col key={value.Id} lg={4} md={6} className="mb-4">
                  <Card
                    className="h-100"
                    onClick={() => onAudioVideoCallClick(value.Id)}
                    style={{
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <Card.Body>
                      <Card.Text>
                        <Row>
                          <Col xs={10}>
                            <div className="consultation-info">
                              <span className="text-styling-one text-red">
                                {value.ConsultationDate}
                              </span>
                            </div>
                            <div className="complaint-info">
                              <span className="text-styling-one">
                                {value.Complaint}
                              </span>
                            </div>
                            <div className="doctor-info">
                              <span className="text-styling-one">
                                {value.DoctorFullName &&
                                  value.DoctorFullName.charAt(0).toUpperCase() +
                                    value.DoctorFullName.slice(1)}
                              </span>
                            </div>
                          </Col>
                          <Col
                            className="d-flex justify-content-end align-items-center"
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                          >
                            <div className="parent-frame-set">
                              <div className="parent-frame-set-child"></div>
                              <img
                                className="parent-frame-set-item"
                                loading="eager"
                                alt=""
                                src="/arrow-2.svg"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </>
          ) : (
            <div className="custom-typography1 text-align-center">
              <p>There is no data.</p>
            </div>
          )}
        </Row>
        {/* {loadingMore && <p className="text-center">Loading...</p>}
            {!loadingMore && !allDataLoaded && (
              <p className="text-center">No more transactions.</p>
            )} */}
    </Container>
  );
};

export default PrescriptionsList;
