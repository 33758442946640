import React, { useEffect } from "react";

const Packages = () => {
  useEffect(() => {
    const cname = sessionStorage.getItem("doctorEmail");
    // alert(cname); 

    // If you want to use cname in other parts of your component, do it here
   console.log(cname);
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  return (
    <div>
      <h1>Packages Page</h1>
      {/* Your component JSX goes here */}
    </div>
  );
};

export default Packages;

