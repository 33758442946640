class accidental_insurance {
  constructor() {
    this.items = [
      {
        id: 197,
        name: "Daily Plan Basic",
        badge: "Daily Silver",
        coverage: "300,000",
        price: "5",
        bgColorClass: "bg-info",
        description: `<p>Quick Online Enrolment: Secure your family&rsquo;s future with easy online sign-up for accidental life insurance.</p>
<ul>
<li><strong>Coverage Amount:</strong> Up to Rs. 300,000 for accidental life insurance</li>
<li><strong>Insurance Period:</strong> 30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Seamless Claims Process:&nbsp;</strong>Experience fast and straightforward claims processing through our friendly support team.</p>`,
        type: "insurance",
      },
      {
        id: 198,
        name: "Monthly Plan Silver",
        badge: "Monthly Silver",
        coverage: "300,000",
        price: "150",
        bgColorClass: "bg-warning",
        description: `<p>Quick Online Enrolment: Secure your family&rsquo;s future with easy online sign-up for accidental life insurance.</p>
<ul>
<li><strong>Coverage Amount:</strong> Up to Rs. 300,000 for accidental life insurance</li>
<li><strong>Insurance Period:</strong> 30 days</li>
<li><strong>Subscription:</strong> Monthly</li>
</ul>
<p><strong>Seamless Claims Process:</strong>&nbsp;Experience fast and straightforward claims processing through our friendly support team.</p>`,
        type: "insurance",
      },
      {
        id: 201,
        name: "Daily Plan Gold",
        badge: "Daily Gold",
        coverage: "600,000",
        price: "10",
        bgColorClass: "bg-info",
        description: `<p>Quick Online Enrolment: Secure your family&rsquo;s future with easy online sign-up for accidental life insurance.</p>
<ul>
<li><strong>Coverage Amount:</strong> Up to Rs. 600,000 for accidental life insurance</li>
<li><strong>Insurance Period:</strong> 30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Seamless Claims Process:</strong> Experience fast and straightforward claims processing through our friendly support team.</p>`,
        type: "insurance",
      },
      {
        id: 1212,
        name: "Monthly Plan Gold",
        badge: "Monthly Gold",
        coverage: "600,000",
        price: "300",
        bgColorClass: "bg-info",
        description: `<p>Quick Online Enrolment: Secure your family&rsquo;s future with easy online sign-up for accidental life insurance.</p>
<ul>
<li><strong>Coverage Amount:</strong> Up to Rs. 600,000 for accidental life insurance</li>
<li><strong>Insurance Period:</strong> 30 days</li>
<li><strong>Subscription:</strong> Monthly</li>
</ul>
<p><strong>Seamless Claims Process:</strong> Experience fast and straightforward claims processing through our friendly support team.</p>`,
        type: "insurance",
      },
      //       {
      //         id: 197,
      //         name: "Daily Plan Basic",
      //         badge: "Basic",
      //         coverage: "300,000",
      //         price: "3",
      //         bgColorClass: "bg-info",
      //         description: `<p>Quick Online Enrolment: Secure your family&rsquo;s future with easy online sign-up for accidental life insurance.</p>
      // <ul>
      // <li><strong>Coverage Amount:</strong> Up to Rs. 300,000 for accidental life insurance</li>
      // <li><strong>Insurance Period:</strong> 30 days</li>
      // <li><strong>Subscription:</strong> Daily</li>
      // </ul>
      // <p><strong>Seamless Claims Process:&nbsp;</strong>Experience fast and straightforward claims processing through our friendly support team.</p>`,
      //         type: "insurance",
      //       },
      //       {
      //         id: 198,
      //         name: "Daily Plan Silver",
      //         badge: "Silver",
      //         coverage: "600,000",
      //         price: "6",
      //         bgColorClass: "bg-warning",
      //         description: `<p>Quick Online Enrolment: Secure your family&rsquo;s future with easy online sign-up for accidental life insurance.</p>
      // <ul>
      // <li><strong>Coverage Amount:</strong> Up to Rs. 600,000 for accidental life insurance</li>
      // <li><strong>Insurance Period:</strong> 30 days</li>
      // <li><strong>Subscription:</strong> Daily</li>
      // </ul>
      // <p><strong>Seamless Claims Process:</strong>&nbsp;Experience fast and straightforward claims processing through our friendly support team.</p>`,
      //         type: "insurance",
      //       },
      //       {
      //         id: 201,
      //         name: "Daily Plan Gold",
      //         badge: "Gold",
      //         coverage: "1,000,000",
      //         price: "12",
      //         bgColorClass: "bg-info",
      //         description: `<p>Quick Online Enrolment: Secure your family&rsquo;s future with easy online sign-up for accidental life insurance.</p>
      // <ul>
      // <li><strong>Coverage Amount:</strong> Up to Rs. 1,000,000 for accidental life insurance</li>
      // <li><strong>Insurance Period:</strong> 30 days</li>
      // <li><strong>Subscription:</strong> Daily</li>
      // </ul>
      // <p><strong>Seamless Claims Process:</strong> Experience fast and straightforward claims processing through our friendly support team.</p>`,
      //         type: "insurance",
      //       },
    ];

    this.terms = `
      <p><strong>EXCLUSIONS:</strong></p>
<p>Payment of claims is subject to exclusions, as outlined below.</p>
<ul>
<li>The Personal Accident Life Insurance Cover shall not cover any Death which is caused by, or resulting, directly or indirectly, wholly or partly, from any of the following factors:</li>
<li>Suicide or any attempt thereat (sane or insane). Kidnapping for ransom.</li>
<li>To any death or injury sustained whilst the Insured is under the inﬂuence (temporarily or otherwise) of intoxicants, alcohol or drugs.</li>
<li>War, strikes, riots, civil commotion and any natural or man&shy;made perils (including chemical, nuclear, biological, and radiological) that results in the declaration of a state of emergency on a local or national level.</li>
</ul>
<p><strong>ELIGIBILITY &amp; ENROLMENT:</strong></p>
<ul>
<li>Applicants are eligible to apply for an Accident Life Protection Plan under the Policy if Applicants meet ALL the criteria set out below:</li>
<li>All Eligible Applicants shall be natural persons. Corporations, partnerships and businesses shall not be eligible for coverage under the Accident Life Policy.</li>
<li>All Eligible Applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
</ul>
<p><strong>MISTAKE IN AGE:</strong></p>
<ul>
<li>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. With the onus on the customer for incorrect disclosure the Company shall not be liable to pay any benefit under this Policy in that case.</li>
</ul>
<p><strong>INTENTIONAL FALSE STATEMENTS OF THE INSURED:</strong></p>
<ul>
<li>In the event of any concealment or misrepresentation the Accident life Policy shall become null and void with respect to the relevant Insured.</li>
</ul>
<p><strong>NOTICE OF CLAIMS:</strong></p>
<ul>
<li>The Company shall be notified of the occurrence of Death of the Insured as soon as possible, but not later than 100 (one hundred) days from the date of Death after which it shall be treated as time barred and the Company shall not be bound to pay the Claim.</li>
</ul>
<p>For each Claim reported, the Company shall obtain: From the Claimant:</p>
<p><strong>In case of Death:</strong></p>
<p>A notice of death, which could be a legal death certificate, a certificate of attending physician, containing his registration number and mentioning actual cause of death, and proof of the personal identity of the Insured, which could be the official personal identity data.</p>
<p>If there is a dispute, suspected fraudulent activity on the claim or a unique situation which requires further clarification, the payment period can be extended but shall not exceed ten (10) working days, or as long as the dispute takes to resolve in the legal system.</p>
<p><strong>TERMINATION OF INDIVIDUAL INSURANCE:</strong></p>
<p>The insurance of an Insured shall automatically terminate at the earliest time below: Upon payment on Death or Upon cancellation or withdrawal of subscription.</p>
<p><strong>NEXT TO KIN REGISTRATION:</strong></p>
<p>Customer should provide the following information to register next to kin:</p>
<ul>
<li>Name of the beneficiary CNIC of the beneficiary Relation with the beneficiary</li>
<li>Mobile number on which the policy is activated.</li>
<li>This information should be sent to the provided Corporate WhatsApp number. The confirmation statement will acknowledge the details provided and next-to-kin will be submitted. Customer may call any time at helpline to confirm the beneficiary details. Customer can change the next to KIN information throughout the policy term at his/her will.</li>
</ul>
<p>All are Insurance policies are on recuring based payment method and can be unsubscribed at any time by calling helpline or through miniapp</p>
<p>Pricing for Products are variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting.</p>
<p>The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>
<p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance company</p>
`;
//     this.terms = `
//   <p><strong>Exclusions</strong></p>
//   <p>Payment of claims is subject to exclusions, as outlined below.</p>
//   <ul>
//     <li>The Personal Accident Life Insurance Cover shall not cover any Death which is caused by, or resulting, directly or indirectly, wholly or partly, from any of the following factors:</li>
//     <li>Suicide or any attempt thereat (sane or insane). Kidnapping for ransom.</li>
//     <li>To any death or injury sustained whilst the Insured is under the inﬂuence (temporarily or otherwise) of intoxicants, alcohol or drugs.</li>
//     <li>War, strikes, riots, civil commotion and any natural or manmade perils (including chemical, nuclear, biological, and radiological) that results in the declaration of a state of emergency on a local or national level.</li>
//   </ul>
//   <p><strong>Eligibility & Enrolment</strong></p>
//   <ul>
//     <li>Applicants are eligible to apply for an Accident Life Protection Plan under the Policy if Applicants meet ALL the criteria set out below:</li>
//     <li>All Eligible Applicants shall be natural persons. Corporations, partnerships and businesses shall not be eligible for coverage under the Accident Life Policy.</li>
//     <li>All Eligible Applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
//   </ul>
//   <p><strong>Mistake in Age</strong></p>
//   <p>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. With the onus on the customer for incorrect disclosure the Company shall not be liable to pay any benefit under this Policy in that particular case.</p>
//   <p><strong>Intentional False Statements of the Insured</strong></p>
//   <p>In the event of any concealment or misrepresentation the Accident life Policy shall become null and void with respect to the relevant Insured.</p>
//   <p><strong>Notice of Claims</strong></p>
//   <p>The Company shall be notified of the occurrence of Death of the Insured as soon as possible, but not later than 100 (one hundred) days from the date of Death after which it shall be treated as time¬barred and the Company shall not be bound to pay the Claim.</p>
//   <p>For each Claim reported, the Company shall obtain:</p>
//   <p><strong>In case of Death:</strong></p>
//   <p>A notice of death, which could be a legal death certificate, a certificate of attending physician, containing his registration number and mentioning actual cause of death, and proof of the personal identity of the Insured, which could be the official personal identity data.</p>
//   <p>If there is a dispute, suspected fraudulent activity on the claim or a unique situation which requires further clarification, the payment period can be extended but shall not exceed ten (10) working days, or as long as the dispute takes to resolve in the legal system.</p>
//   <p><strong>Termination of Individual Insurance</strong></p>
//   <p>The insurance of an Insured shall automatically terminate at the earliest time below:</p>
//   <ul>
//     <li>Upon payment on Death or</li>
//     <li>Upon cancellation or withdrawal of subscription</li>
//   </ul>
//   <p><strong>Next to Kin Registration</strong></p>
//   <p>Customer should provide the following information to register next to kin:</p>
//   <ol>
//     <li>Name of the beneficiary CNIC of the beneficiary Relation with the beneficiary</li>
//     <li>Mobile number on which the policy is activated.</li>
//     <li>•	This information should be sent to the provided Corporate WhatsApp number. The confirmation statement will acknowledge the details provided and next-to-kin will be submitted. Customer may call any time at helpline to confirm the beneficiary details. Customer can change the next to KIN information throughout the policy term at his/her will.</li>
//   </ol>
//   <p>All are Insurance policies are on recuring based payment method and can be unsubscribed at any time by calling helpline or through miniapp 
// Pricing for Products are variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting.
// The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.
// </p>
// <p><strong>Disclaimer: </strong></p>
// <p>This insurance is underwritten by East West Insurance company</p>
// `;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default accidental_insurance;
