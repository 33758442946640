class health_insurance {
  constructor() {
    this.items = [
      {
        id: 169,
        name: "Annual Plan",
        badge: "Annual",
        coverage: "100,000",
        price: "2,000",
        bgColorClass: "bg-info",
        description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
<ul>
<li>I<strong>n-Patient Department (IPD) Coverage:</strong> PKR. 100,000 (Rs. 10,000/day limit)  - For Whole Family including parents.</li>
<li><strong>Inclusive Protection:</strong> Coverage extends to the entire family, including parents.</li>
<li><strong>Unlimited Tele-Doctor Consultations:</strong> Unlimited Tele doctor consultations for whole family!</li>
</ul><p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
        type: "insurance",
      },
      {
        id: 170,
        name: "Annual Plan",
        badge: "Annual",
        coverage: "30,000",
        price: "1,000",
        bgColorClass: "bg-warning",
        description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> PKR, 30,000 (Consumable in one go), Accidental OPD: PKR 5,000</li>
<li><strong>Accidental Out-Patient Department (OBD) Coverage:</strong> Up to PKR 5,000</li>
<li><strong>Unlimited Tele-Doctor Consultations:</strong> Unlimited Tele doctor consultations for whole family!</li>
</ul>
<p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
        type: "insurance",
      },
      {
        id: 171,
        name: "6 Months",
        badge: "6 Months",
        coverage: "30,000", 
        price: "500",
        bgColorClass: "bg-info",
        description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> PKR, 30,000 (PKR. 7,000/day limit), Accidental OBD: PKR 5,000</li>
<li><strong>Accidental Out-Patient Department (OPD) Coverage:</strong> Up to PKR 5,000</li>
<li><strong>Unlimited Tele-Doctor Consultations:</strong> Unlimited Tele doctor consultations for whole family!</li>
</ul>
<p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
        type: "insurance",
      },
      {
        id: 172,
        name: "Monthly Plan",
        badge: "Monthly",
        coverage: "10,000/-",
        price: "60",
        bgColorClass: "bg-warning",
        description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> PKR. 10,000 (Pro-rated) </li>
<li><strong>Consultation Calls:</strong> 5 consultation calls / month for whole family</li>
</ul>
<p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
        type: "insurance",
      },
      {
        id: 173,
        name: "Daily Plan",
        badge: "Daily",
        coverage: "10,000",
        price: "2",
        bgColorClass: "bg-warning",
        description: `<p><strong>Instant Enrolment:</strong> Secure your health with quick online sign-up for your insurance coverage.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> PKR. 10,000 (Pro-rated) </li>
<li><strong>Consultation Calls:</strong> 5 consultation calls / month for whole family</li>
</ul>
<p><strong>Seamless Claims:</strong> Experience hassle-free claims with our friendly customer support.</p>`,
        type: "insurance",
      },
    ];


    this.terms = `<p><strong>POLICY TERMS & EXCLUSIONS&nbsp;</strong></p>
<ul>
  <li>All plans are auto renew for next 5 years or till attainment of maximum covered age of the customer whichever comes earlier.</li>
  <li>Pre-existing conditions are not covered.</li>
  <li>Cosmetic or surgical procedures are not covered.</li>
  <li>Regular medicine & SI (Surgical Instruments) doesn’t fall under IPD.</li>
  <li>MMC (Major Medical Care) & DD (Dread Disease) cover will apply under IPD admission cover Only.</li>
  <li>Cover restricts to IPD, Patient must be admitted at least 24 hours on bed to avail cover.</li>
  <li>Day care surgeries or any admission less than 24 hours including emergencies are not covered.</li>
  <li>No Maternity Cover apply.</li>
  <li>Policy can be reversed within 15 days after subscription only for 6 month and annual plan.</li>
</ul>
<p><strong>DOCTORS CONSULTANCY & TIMING:</strong></p>
<p>Doctors services are available from Monday – Saturday 9:00am – 9:00pm.</p>
<p><strong>CLAIMS PAYMENT:</strong></p>
<ul>
  <li>Claim payment TAT is 15 working days after complete documents submission.</li>
</ul>
<p><strong>REQUIRED DOCUMENTS:</strong></p>
<ol>
  <li>CNIC Copy (Both sides)</li>
  <li>Treatment details/Doctor’s prescription</li>
  <li>Admission & discharge slip</li>
  <li>Payment receipt/bills (payment paid by patient/customer in hospital)</li>
  <li>Lab reports, if any</li>
  <li>MSISDN & Easypaisa account</li>
</ol>`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default health_insurance;
