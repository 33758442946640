// src/components/Card.js
import React from "react";
import "../../src/style.css";
const Card = ({ image, title, subtitle }) => {
  return (
    <div className="custom-card p-2" >
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <img src={image} className="card-img-top text-center" alt={title}  />
        </div>
      </div>
      <div className="card-body">
      <div className="row">
        <div className="col-12">
            <p className="card-title text-center p-2 mt-2" style={{fontSize:'10px'}}>{title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
