import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useLoading } from "../contexts/LoadingContext";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import "./StepRangeSlider.css";
const CenteredModal = ({ show, handleClose, terms }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="p-3"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Terms And Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: terms }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PackageScreen = ({ items, terms }) => {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();
  const [selectedOption, setSelectedOption] = useState(items[0]);
  const [description, setDescription] = useState(
    items.length > 0 ? items[0].description : ""
  );
  const [coverage, setCoverage] = useState(
    items.length > 0 ? items[0].coverage : ""
  );

  const [isChecked, setIsChecked] = useState(true);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleButtonClick = (item) => {
    setSelectedOption(item);
    setDescription(item.description);
    setCoverage(item.coverage);
  };

  const byClickingBuyPackage = useCallback((id, price, type, coverage) => {
    setIsLoading(true);
    const item = sessionStorage.getItem("user");
    const sess = JSON.parse(item);

    const form = new FormData();
    form.append("value", Number(price) * 100);

    const settings = {
      method: "POST",
      body: form,
    };

    fetch(
      "https://easypaiseminiapp.webddocsystems.com/public/api/v1/create-payment",
      settings
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.paymentTransactionID) {
          setIsLoading(false);
          if (type === "calladoc") {
            sess["package"] = "true";
            const updatedItem = JSON.stringify(sess);
            sessionStorage.setItem("user", updatedItem);
          }
          // if payment issue then commit this like and then check on browser
          window.mp.createPayment(result.paymentTransactionID);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });

    // if payment issue then comment this line and then check on browser
    // =====>
    window.createPaymentResult = (jsonResult) => {
      const result = JSON.parse(jsonResult);
      setIsLoading(true);
      navigate(`/confirm`, {
        state: {
          type,
          price,
          coverage: coverage,
          pid: id,
          status: result.paymentStatus,
          code: result.resultCode,
          tid: result.paymentTransactionID,
        },
      });
    };


    // =====>
    // if payment issue then uncomment this line and then check on browser

    // navigate(`/confirm`, {
    //   state: {
    //     type,
    //     price,
    //     coverage: coverage,
    //     pid: id,
    //     status: "S",
    //     code: "SUCCESS",
    //     tid: "1123123",
    //   },
    // });


  }, []);

  useEffect(() => {
    const redirectFromPaymentFaild = sessionStorage.getItem(
      "redirectFromPaymentFaild"
    );

    if (redirectFromPaymentFaild === "true") {
      Swal.fire({
        icon: "info",
        title: "Payment Faild",
        text: "Something went wrong please try again.",
        confirmButtonText: "Ok",
      }).then(() => {
        sessionStorage.removeItem("redirectFromPaymentFaild");
      });
    }
  }, []);

  const handlePayNowClick = () => {
    const { id, price, type } = selectedOption;
    byClickingBuyPackage(id, parseInt(price), type, coverage);
  };

  return (
    <>
      <div style={{ marginBottom: "110px" }}>
        <div className="row m-3 mt-2 position-relative">
          <div className="col-12 text-center">
            <p className="custome-textfont">
              Select price point to subscribe to the service
            </p>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="row d-flex justify-content-center">
                {items.map((item, index) => (
                  <div className="col-4 text-center mb-1 p-2" key={index}>
                    <Button
                      variant={
                        selectedOption.id === item.id
                          ? "success"
                          : "outline-secondary"
                      }
                      className="w-100 p-2"
                      onClick={() => handleButtonClick(item)}
                    >
                      {item.badge} <br></br>
                      Rs. {item.price}
                    </Button>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col-12">
                  <hr />
                </div>
              </div>
              {coverage !== "-" && (
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-column">
                      <p className="dark-border rounded-border bg-secondary p-3 fs-15 text-white">
                        Coverage:
                        <strong
                          className="float-end"
                          style={{ fontSize: "15px", color: "white" }}
                        >
                          {" "}
                          Rs. {coverage}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  <p
                    id="description"
                    className="custome-textfont"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-bottom-center p-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <small>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />{" "}
                By clicking <strong>"Pay Now"</strong> you are accepting our{" "}
                <br></br>
                <a href="javascript:void(0);" onClick={handleShow}>
                  Terms & conditions
                </a>
              </small>

              <button
                className="btn btn-success w-100 p-2"
                onClick={handlePayNowClick}
                disabled={!isChecked}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <CenteredModal show={show} handleClose={handleClose} terms={terms} />
    </>
  );
};

export default PackageScreen;
