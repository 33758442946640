import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../contexts/LoadingContext";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./StepRangeSlider.css";
const InsuranceComponent = ({ items, terms }) => {
  const navigate = useNavigate();
  const [rangeValue, setRangeValue] = useState(30000);
  const [selectedValue, setSelectedValue] = useState(30000);
  const [selectedOption, setSelectedOption] = useState("monthly");
  const [yearlyAmount, setYearlyAmount] = useState(0);
  const [monthlyAmount, setMonthlyAmount] = useState(0);
  // const [dailyAmount, setDailyAmount] = useState(0);
  const { isLoading, setIsLoading } = useLoading();
  const [description, setDescription] = useState(
    items.length > 0 ? items[0].description : ""
  );
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [isChecked, setIsChecked] = useState(true);
  const CenteredModal = ({ show, handleClose, terms }) => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="p-3"
        backdrop="static" // This makes the modal static
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms And Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: terms }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  useEffect(() => {
    calculateAmounts(rangeValue);
    updateSliderBackground(rangeValue);
  }, [rangeValue]);

  useEffect(() => {
    const selectedItem = items.find(
      (item) => item.badge.toLowerCase() === selectedOption.toLowerCase()
    );
    if (selectedItem) {
      setDescription(selectedItem.description);
    }
  }, [selectedOption, items]);

  const calculateAmounts = (value) => {
    const yearly = value * 0.09 + 50;
    const monthly = (value * 0.09) / 9;
    // const daily = (value * 0.09) / 9 / 20;

    setYearlyAmount(yearly.toFixed(0));
    setMonthlyAmount(monthly.toFixed(0));
    // setDailyAmount(daily.toFixed(0));
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const updateSliderBackground = (value) => {
    const percentage = ((value - 30000) / 120000) * 100;
    const slider = document.getElementById("rangeSlider");
    slider.style.background = `linear-gradient(to right, #4CAF50 ${percentage}%, #ddd ${percentage}%)`;
  };

  const handleRangeChange = (event) => {
    const value = parseInt(event.target.value);
    setRangeValue(value);
    setSelectedValue(value);
    updateSliderBackground(value);
  };

  const byClickingBuyPackage = useCallback((id, price, type, selectedValue) => {
    setIsLoading(true);
    const item = sessionStorage.getItem("user");
    const sess = JSON.parse(item);

    const form = new FormData();
    form.append("value", price);

    const settings = {
      method: "POST",
      body: form,
    };

    fetch(
      "https://easypaiseminiapp.webddocsystems.com/public/api/v1/create-payment",
      settings
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.paymentTransactionID) {
          setIsLoading(false);
          if (type === "calladoc") {
            sess["package"] = "true";
            const updatedItem = JSON.stringify(sess);
            sessionStorage.setItem("user", updatedItem);
          }
          // if payment issue then commit this like and then check on browser
          window.mp.createPayment(result.paymentTransactionID);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });

    // if payment issue then comment this line and then check on browser
    // =====>
    window.createPaymentResult = (jsonResult) => {
      const result = JSON.parse(jsonResult);
      setIsLoading(true);
      navigate(`/confirm`, {
        state: {
          type,
          price,
          coverage: selectedValue,
          pid: id,
          status: result.paymentStatus,
          code: result.resultCode,
          tid: result.paymentTransactionID,
        },
      });
    };

    // =====>
    // if payment issue then uncomment this line and then check on browser

    // navigate(`/confirm`, {
    //   state: {
    //     type,
    //     price,
    //     coverage: selectedValue,
    //     pid: id,
    //     status: "S",
    //     code: "SUCCESS",
    //     tid: "1123123",
    //   },
    // });
  }, []);

  const handlePayNowClick = () => {
    const selectedItem = items.find(
      (item) => item.badge.toLowerCase() === selectedOption.toLowerCase()
    );

    if (selectedItem) {
      let selectedPrice;
      switch (selectedOption.toLowerCase()) {
        case "yearly":
          selectedPrice = yearlyAmount;
          break;
        case "monthly":
        default:
          selectedPrice = monthlyAmount;
          break;
        // case "daily":
        // default:
        //   selectedPrice = dailyAmount;
        //   break;
      }
      byClickingBuyPackage(
        selectedItem.id,
        parseInt(selectedPrice),
        selectedItem.type,
        formatNumber(selectedValue)
      );
    }
  };

  return (
    <>
      <div className="row m-3 mt-2 position-relative">
        <div className="card packages_card mb-3 position-relative">
          <div className="row g-0">
            <div className="col-12">
              <div className="card-body">
                <Row className="my-4">
                  <Col className="text-center">
                    <div className="range-slider">
                      <input
                        id="rangeSlider"
                        type="range"
                        min="30000"
                        max="150000"
                        step="10000"
                        value={rangeValue}
                        onChange={handleRangeChange}
                        className="form-range"
                        style={{ width: "100%" }}
                      />
                      <div
                        className="range-value"
                        style={{
                          left: `${((rangeValue - 30000) / 120000) * 100}%`,
                        }}
                      >
                        Rs {formatNumber(rangeValue)}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <span>Rs 30,000</span>
                      <span>Rs 150,000</span>
                    </div>

                    {/* <p>Selected Coverage: Rs {formatNumber(rangeValue)}</p> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex flex-column">
                      <p className="dark-border rounded-border bg-secondary p-3 fs-15 text-white">
                        Coverage:
                        <strong
                          className="float-end"
                          style={{ fontSize: "15px", color: "white" }}
                        >
                          {" "}
                          upto Rs.{formatNumber(rangeValue)}
                        </strong>
                      </p>
                    </div>
                  </Col>
                </Row>

                <div className="row d-flex align-items-center justify-content-center">
                  {["Monthly", "Yearly"].map((badge, index) => {
                    let amount;
                    switch (badge.toLowerCase()) {
                      case "yearly":
                        amount = yearlyAmount;
                        break;
                      case "monthly":
                      default:
                        amount = monthlyAmount;
                        break;
                      case "daily":
                      // default:
                      //   amount = dailyAmount;
                      //   break;
                    }
                    return (
                      <Col
                        key={index}
                        xs={4}
                        sm={4}
                        className="text-center mb-1 p-1"
                      >
                        <Button
                          className="w-100 p-0"
                          variant={
                            selectedOption.toLowerCase() === badge.toLowerCase()
                              ? "success"
                              : "outline-secondary"
                          }
                          onClick={() => setSelectedOption(badge)}
                        >
                          {badge} <br />
                          Rs. {formatNumber(amount)}
                        </Button>
                      </Col>
                    );
                  })}
                </div>
                <div className="row">
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <p
                      id="description"
                      className="custome-textfont"
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-bottom-center p-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <small>
                By clicking <strong>"Pay Now"</strong> you are accepting our{" "}
                <br></br>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />{" "}
                Agree to pay recursively .
                <a href="javascript:void(0);" onClick={handleShow}>
                  Terms & conditions
                </a>
              </small>

              <button
                className="btn btn-success w-100 p-2"
                onClick={handlePayNowClick}
                disabled={!isChecked}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <CenteredModal show={show} handleClose={handleClose} terms={terms} />
    </>
  );
};

export default InsuranceComponent;
