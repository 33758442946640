import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const PaymentConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params, setParams] = useState({});

  useEffect(() => {
    const state = location.state || {};
    setParams(state);
    console.log(state);
    const userData = JSON.parse(sessionStorage.getItem("user"));

    if (state.status === "S") {
      const handleSubscription = (url, payload) => {
        
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
            Authorization:
              "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
          },
          body: JSON.stringify(payload),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.responseCode === "0000") {
              sessionStorage.setItem("redirectFromPaymentSuccess", "true");
              navigate(-1);
            } else {
              console.error("Transaction failed:", data.message);
              sessionStorage.setItem("redirectFromPaymentFaild", "true");
              navigate(-1);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            sessionStorage.setItem("redirectFromPaymentFaild", "true");
            navigate(-1);
          });
      };
      console.log(state.coverage);
      const payload = {
        openId: userData.openId,
        orderId: state.tid,
        Price: String(state.price),
        coverage: String(state.coverage),
        transactionStatus: "Success",
        mobileNumber: userData.userMsisdn,
        name: userData.userName,
        transactionId: state.tid,
        packageId: parseInt(state.pid, 10),
      };

      if (state.type === "calladoc") {
        const formData = new FormData();
        formData.append("pid", state.pid);
        formData.append("patientid", userData.applicationUserId);
        formData.append("openId", userData.openId);
        //This api function is hosted on plesk server
        fetch(
          "https://easypaiseminiapp.webddocsystems.com/public/api/v1/subscription",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then(() => {
            sessionStorage.setItem("redirectFromPaymentSuccess", "true");
            navigate(`/`);
          })
          .catch((error) => {
            console.error("Error:", error);
            sessionStorage.setItem("redirectFromPaymentFaild", "true");
            navigate(`/`);
          });
      } else {
        let url = "";
        switch (state.type) {
          case "insurance":
            url =
              "https://insuranceservice.webddocsystems.com/api/MiniApp/ActivatePackage";
            break;
          case "rsa":
            //Agr isko call krwna hai tu Mr. Fahad se contact krna hai.
            // url = "https://insuranceservice.webddocsystems.com/api/MiniApp/MiniAppPackageActivationRSA";
            url =
              "https://insuranceservice.webddocsystems.com/api/MiniApp/ActivatePackage";
            break;
          case "agri":
            url =
              "https://insuranceservice.webddocsystems.com/api/MiniApp/MiniAppPackageActivationAgri";
            break;
          case "vets":
            url =
              "https://insuranceservice.webddocsystems.com/api/MiniApp/MiniAppPackageActivationVetnary";
            break;
          default:
            navigate(`/`);
            return;
        }
        handleSubscription(url, payload);
      }
    } else {
      alert("Payment failed.");
      navigate(-1);
    }
  }, [location, navigate]);

  return <div></div>;
};

export default PaymentConfirm;
