import React from "react";
import "./header.css";

const Header = (props) => {
  // const user = {
  //   openId: "5da34d83de18ba92f44040c10b406c8ad4bb1375",
  //   unionId: "5ba759f37b91d2a51f20c014a7c3ba2a504055c7",
  //   userMsisdn: "0345000014",
  //   userName: "Khumash Hussain",
  //   applicationUserId: "27C62D9C-AB40-450C-A6CE-7851F47190FA",
  //   // applicationUserId: "2F02828E-B0E0-4570-9815-CDFBFB7D6DD0",
  //   userEmail: "03145362496@webdoc.com.pk",
  //   // applicationUserId: "B11000A1-70CA-4E67-A2A1-11DF65FEE0D9",
  //   package: "",
  //   message: "User registered and authenticated successfully  .",
  // };

  // sessionStorage.setItem("user", JSON.stringify(user));
  // console.log(sessionStorage.getItem("user"));

  // const userData = JSON.parse(sessionStorage.getItem("user"));
  return (
    <div className="bg-dark text-white d-flex justify-content-between align-items-center main-div">
      <div className="row mb-3 mt-3">
        <div className="col-6 d-flex align-items-center justify-content-center logo">
          <img
            src="assets/NewMiniapp/logo.png"
            alt="Logo"
            style={{ width: "50%" }}
          />
        </div>

        <div className="col-6 d-flex align-items-center justify-content-end">
          <div className="me-3 mt-3">
            {props.userSession ? (
              <>
                <span>Hey, </span>
                <br />
                <span>{props.userSession.userName}</span>
                <br />
                <span>{props.userSession.userMsisdn}</span>
                <br />
              </>
            ) : (
              <span>Loading...</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
