class vets {
  constructor() {
    this.items = [
      {
        id: 1,
        name: "Vet Doctors",
        badge: "Monthly",
        coverage: "-",
        price: "100",
        bgColorClass: "bg-info",
        description: `<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;'>The livestock industry supports over 70% of Pakistan&apos;s population. At WEBDOC, we offer affordable, expert veterinary care for livestock and pets. Our vets provide state-of-the-art consultations Monday to Saturday 9 AM to 9 PM to ensure your animals thrive.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;'><strong>Product Features:</strong></p>
<ul style="list-style-type: disc;">
    <li><strong>Affordable Care:</strong> Cost-effective solutions for your livestock and pet health needs.</li>
    <li><strong>Expert Advice:</strong> Receive guidance from experienced veterinary doctors.</li>
    <li><strong>Comprehensive Support:</strong> Assistance with major issues, ensuring the well-being of your animals.</li>
    <li><strong>Quick Response:</strong> Immediate consultations to address urgent health concerns.</li>
    <li><strong>Wide Range of Services:</strong> Covers both preventive and emergency care for livestock and pets.</li>
</ul>`,
        type: "vets",
      },
      {
        id: 2,
        name: "Vet Doctors",
        badge: "Annual",
        coverage: "-",
        price: "700",
        bgColorClass: "bg-warning",
        description: `<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;'>The livestock industry supports over 70% of Pakistan&apos;s population. At WEBDOC, we offer affordable, expert veterinary care for livestock and pets. Our vets provide state-of-the-art consultations Monday to Saturday 9 AM to 9 PM to ensure your animals thrive.</p>
<p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;font-size:11.0pt;font-family:"Calibri",sans-serif;text-align:justify;'><strong>Product Features:</strong></p>
<ul style="list-style-type: disc;">
    <li><strong>Affordable Care:</strong> Cost-effective solutions for your livestock and pet health needs.</li>
    <li><strong>Expert Advice:</strong> Receive guidance from experienced veterinary doctors.</li>
    <li><strong>Comprehensive Support:</strong> Assistance with major issues, ensuring the well-being of your animals.</li>
    <li><strong>Quick Response:</strong> Immediate consultations to address urgent health concerns.</li>
    <li><strong>Wide Range of Services:</strong> Covers both preventive and emergency care for livestock and pets.</li>
</ul>`,
        type: "vets",
      },
    ];

    this.terms = `<p>Digital doctors are available either through application or by dialing the helpline number. Fair usage policy strictly applies.:&nbsp;</p>`
      ;
    // this.terms = `<p><strong>Personal data collection:&nbsp;</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>To fully utilize our services, registration with the website is necessary.&nbsp;</li>
    //   <li>During signup, the website will request your data and information. This data is securely stored.</li>
    //   <li>The personal data collected is used solely to facilitate access to our services. No data or personal information will be collected without your explicit consent.&nbsp;</li>
    //   <li>This may include your name, CNIC, email address, and contact number.</li>
    //   <li>We do not ask for your passport numbers, driver's license numbers, bank card numbers, or any other registration numbers provided by third parties.</li>
    //   <li>You'll need to create a password for your private account. It's crucial to safeguard this password and avoid sharing it with any other entities or using it for third-party services.</li>
    //   </ul>
    //   <p><strong>Encryption:&nbsp;</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>For enhanced security and uninterrupted service, we encrypt your data and password.&nbsp;</li>
    //   <li>By using our services, you agree to our privacy policy. We reserve the right to change the encryption policy without prior notice, ensuring maximum security.</li>
    //   </ul>
    //   <p><strong>Personal information we may use:&nbsp;</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>Information provided during signup is only used for notifications and enhancing user experience, safeguarded and not shared without permission.&nbsp;</li>
    //   <li>The website is not liable for data loss or security breaches.</li>
    //   </ul>
    //   <p><strong>Queries and complaints:&nbsp;</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>For inquiries about our services or website content, please contact our customer service department.&nbsp;</li>
    //   </ul>
    //   <p><strong>Content creation/copyrights</strong></p>
    //   <ul style="margin-left:0;padding-left:0">
    //   <li>All content uploaded on the website is copyrighted and owned by us, aimed at enhancing user services.&nbsp;</li>
    //   <li>Users can create and manage content, and they are solely responsible for it.&nbsp;</li>
    //   <li>We are not liable for any reported or banned user-generated content.&nbsp;</li>
    //   <li>Users must take responsibility for their personal information and created content.&nbsp;</li>
    //   <li>Our privacy policy applies to all users, ensuring the best services and user experience.&nbsp;</li>
    //   <li>The privacy policy may change without prior notice.&nbsp;</li>
    //   <li>For inquiries about the privacy policy, contact&nbsp;<a href="mailto:support@webdoc.com.pk">support@webdoc.com.pk</a>.&nbsp;</li>
    //   <li>We use cookies to enhance user experience and track navigation and search information.&nbsp;</li>
    //   </ul>`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default vets;
