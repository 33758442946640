class income_protection {
  constructor() {
    this.items = [
      {
        id: 165,
        name: "Daily Plan",
        badge: "Daily",
        coverage: "10,000",
        price: "1",
        bgColorClass: "bg-info",
        description: `<p>Fast Online Sign-up: Safeguard your income with easy online sign-up for reliable protection insurance.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> Up to 10,000 IPD every month (max Rs.500/day), max 20 days a month</li>
</ul>
<p><strong>Effortless Claims:</strong> Enjoy swift, hassle-free claims processing through our dedicated support team.</p>`,
        type: "insurance",
      },
      {
        id: 166,
        name: "Monthly Insurance",
        badge: "Monthly",
        coverage: "20,000",
        price: "100",
        bgColorClass: "bg-warning",
        description: `<p>Fast Online Sign-up: Safeguard your income with easy online sign-up for reliable protection insurance.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> Up to 20,000 IPD every month (max Rs.2,000/day), max 10 days a month</li>
</ul>
<p><strong>Effortless Claims:</strong>&nbsp;Enjoy swift, hassle-free claims processing through our dedicated support team.</p>`,
        type: "insurance",
      },
      {
        id: 167,
        name: "6 Months",
        badge: "Half Year",
        coverage: "50,000",
        price: "350",
        bgColorClass: "bg-info",
        description: `<p>Fast Online Sign-up: Safeguard your income with easy online sign-up for reliable protection insurance.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> Up to Rs.50,000 for 6 month (Max Rs.2,000/day), max 25 days in 6 months</li>
</ul>
<p><strong>Effortless Claims:</strong>Enjoy swift, hassle-free claims processing through our dedicated support team.</p>`,
        type: "insurance",
      },
      {
        id: 168,
        name: "Annual Plan",
        badge: "Annual",
        coverage: "50,000",
        price: "700",
        bgColorClass: "bg-warning",
        description: `<p>Fast Online Sign-up: Safeguard your income with easy online sign-up for reliable protection insurance.</p>
<ul>
<li><strong>In-Patient Department (IPD) Coverage:</strong> Up to Rs.50,000 for 12 month (Max Rs.2,000/day), max 25 days in 1 year</li>
</ul>
<p><strong>Effortless Claims:</strong> Enjoy swift, hassle-free claims processing through our dedicated support team.</p>`,
        type: "insurance",
      },
    ];

    this.terms = `
  <p><strong>Policy Terms & Exclusions</strong></p>
  <p>Below are the terms and exclusions for the insurance policy:</p>
  <ul>
    <li>All plans automatically renew for the next 5 years or until the customer reaches the maximum covered age, whichever comes earlier.</li>
    <li>Pre-existing conditions are not covered.</li>
    <li>Cosmetic or surgical procedures are not covered.</li>
    <li>Regular medicine & SI (Surgical Instruments) are not covered under IPD.</li>
    <li>MMC (Major Medical Care) & DD (Dread Diseases) cover applies only under IPD admission.</li>
    <li>Hospitalization includes all diseases/disabilities and admission due to accident.</li>
    <li>Coverage is restricted to IPD; patient must be admitted for at least 24 hours on bed to avail cover.</li>
    <li>Day care surgeries or admissions less than 24 hours, including emergencies, are not covered.</li>
    <li>Maternity admissions or related surgeries are excluded.</li>
    <li>Policy can be canceled within 15 days after subscription, applicable only for 6-month and annual plans.</li>
  </ul>
  <p><strong>Doctors Consultancy & Timing</strong></p>
  <p>Doctor services are available from Monday to Saturday, 9:00am to 9:00pm.</p>
  <p><strong>Claims Payment</strong></p>
  <p>Claims will be paid within 15 working days after complete document submission.</p>
  <p><strong>Required Documents</strong></p>
  <p>The following documents are required for claims:</p>
  <ol>
    <li>CNIC Copy (Both sides)</li>
    <li>Treatment details/Doctor’s prescription</li>
    <li>Admission & discharge slip</li>
    <li>Proof of income loss/salary deduction</li>
    <li>Lab reports, if any</li>
    <li>MSISDN & Easypaisa account</li>
  </ol>
`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default income_protection;
