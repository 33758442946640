// src/components/ActivatedPackageComponent.js
import React from "react";
import Swal from "sweetalert2";
import "../../src/style.css";

const ActivatedPackageComponent = ({ items }) => {
  const redirectFromPaymentSuccess = sessionStorage.getItem(
    "redirectFromPaymentSuccess"
  );
  if (redirectFromPaymentSuccess === "true") {
    Swal.fire({
      icon: "info",
      title: "Confirmation",
      text: "Your package has been activated successfully.",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    }).then(() => {
      // Clear the flag from sessionStorage
      sessionStorage.removeItem("redirectFromPaymentSuccess");
    });
  }
  // console.log(items);
  return (
    <div className="container">
      <div className="row mt-2  position-relative">
        <div className="col-12 pt-2 pb-3 mb-3 text-center border-bottom">
          <h3 class="pb-2">
            <span class="badge bg-success mb-1 mt-1 fs-12">Package Active</span>
          </h3>
          <span>
            Active Date : {items.activateDate}
            <br></br>
            Expire Date : {items.expiryDate}
          </span>
        </div>
        <div className="col-12">
          {items.type === "agri" || items.type === "vets" ? (
            <p className="dark-border rounded-border bg-secondary p-3  fs-15 text-center">
              <strong style={{ fontSize: "20px", color: "white" }}>
                Call Now 03443333737
              </strong>
            </p>
          ) : (
            <div className="d-flex flex-column">
              <p className="dark-border rounded-border bg-secondary p-3  fs-15 text-white">
                Coverage:
                <strong
                  className="float-end"
                  style={{ fontSize: "15px", color: "white" }}
                >
                  {" "}
                  Rs.{items.coverage} /-
                </strong>
              </p>
            </div>
          )}
        </div>
        <div className="col-12">
          <p
            className="custome-textfont"
            dangerouslySetInnerHTML={{ __html: items.features }}
          ></p>
        </div>

        {/* <p className="card-text mt-3">{items.features}</p> */}
      </div>
    </div>
  );
};

export default ActivatedPackageComponent;
