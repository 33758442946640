class PocketInsurance {
  constructor() {
    this.items = [
      {
        id: 1212,
        image: "assets/MiniAppAssets/car.png",
        name: "Car Keys Insurance",
        badge: "Monthly Premium",
        coverage: "100,000",
        price: "200",
        bgColorClass: "bg-warning",
        description: `
          <p><strong>Overview:</strong> 
          Pocket Size Insurance is a micro-insurance product offering affordable coverage for specific risks, including accidents and the loss of important documentation or personal items such as <strong>Car Keys, Transcripts, Degrees, Wearable Gadgets, Watches, Passports, CNIC</strong>. The product is designed to provide essential protection at a low premium with simple terms to enhance accessibility for a broad customer base.</p>
          
          <p><strong>Key Features:</strong></p>
          <ol>
            <li><strong>Low Premium:</strong> 
              The insurance plan is highly affordable, with premiums starting from as low as Rs. 50 per month, ensuring that individuals across various income levels can benefit from this coverage.
            </li>
            <li><strong>Basic Coverage:</strong> 
              The policy covers a variety of minor losses, including:
              <ul>
                <li>Loss of Car Keys</li>
                <li>Loss of Transcripts and Degrees</li>
                <li>Burglary of Smart Watches</li>
                <li>Loss of Passports</li>
                <li>Loss of CNICs</li>
                <li>Loss of House Registry</li>
              </ul>
            </li>
            <li><strong>Accessibility:</strong> 
              The entire insurance process, from purchase to claim management, is fully digitized. The product can be purchased and managed through a mini app, making it easy for policyholders to access insurance services anytime, anywhere.
            </li>
            <li><strong>Hassle-Free Claims:</strong> 
              Claims under this insurance plan are processed with minimal paperwork, ensuring a quick, efficient, and stress-free experience for the insured. The claim process is designed to be user-friendly and fast, removing the traditional barriers often associated with insurance claims.
            </li>
          </ol>

          <p><strong>Premium and Coverage Details:</strong></p>
          <ul>
            <li>Premium Amount: Starting from Rs. 100 per month</li>
            <li>All insurance policies operate on a recurring payment basis and can be unsubscribed at any time by calling the helpline or through the mini app.</li>
            <li>Coverage Limit: Based on the item insured (to be determined by policy specifics for each category of loss)</li>
          </ul>

          <p><strong>Exclusions:</strong></p>
          <ul>
            <li>Pre-existing damages or loss before policy activation</li>
            <li>Intentional damage or fraudulent claims</li>
            <li>Loss due to gross negligence (e.g., leaving items unattended in public places)</li>
            <li>Items not specifically listed in the insured category or under coverage limits</li>
          </ul>

          <p><strong>Claims Process:</strong></p>
          <ol>
            <li><strong>Report the Loss:</strong> 
              Policyholders can report any loss through the app/WhatsApp by submitting basic information and proof of the incident.
            </li>
            <li><strong>Required Documents:</strong> 
              Police report, receipts, or certifications of ownership, CNIC.
            </li>
            <li><strong>Claim Assessment:</strong> 
              The insurance team will assess the claim within 48 hours of submission.
            </li>
            <li><strong>Claim Approval and Payout:</strong> 
              Approved claims will be processed, and the payout will be made directly to the policyholder’s account within 5 working days after completion of documentation.
            </li>
          </ol>

          <p><strong>Eligibility & Enrolment:</strong></p>
          <ul>
            <li>Applicants are eligible to apply for this insurance Plan if they meet ALL the criteria set out below:</li>
            <li>All eligible applicants shall be natural persons. Corporations, partnerships, and businesses shall not be eligible for coverage under this Policy.</li>
            <li>All eligible applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
            <li>A waiting period of 7 days applies.</li>
          </ul>

          <p><strong>Mistake in Age:</strong></p>
          <p>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. The onus is on the customer for incorrect disclosure; the Company shall not be liable to pay any benefit under this Policy in that case.</p>

          <p><strong>Intentional False Statements of the Insured:</strong></p>
          <p>In the event of any concealment or misrepresentation, the insurance Policy shall become null and void with respect to the relevant Insured.</p>

          <p><strong>Notice of Claims:</strong></p>
          <p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN) days from the date of loss, which shall be treated as time-barred, and the Company shall not be bound to pay the Claim.</p>

          <p>Pricing for Products is variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting. The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>

          <p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance Company.</p>
        `,
        type: "insurance",
      },
      {
        id: 1213,
        image: "assets/MiniAppAssets/car.png",
        name: "Car documents Insurance",
        badge: "Monthly Premium",
        coverage: "50,000",
        price: "100",
        bgColorClass: "bg-warning",
        description: `
          <p><strong>Overview:</strong> 
          Pocket Size Insurance is a micro-insurance product offering affordable coverage for specific risks, including accidents and the loss of important documentation or personal items such as <strong>Car Keys, Transcripts, Degrees, Wearable Gadgets, Watches, Passports, CNIC</strong>. The product is designed to provide essential protection at a low premium with simple terms to enhance accessibility for a broad customer base.</p>
          
          <p><strong>Key Features:</strong></p>
          <ol>
            <li><strong>Low Premium:</strong> 
              The insurance plan is highly affordable, with premiums starting from as low as Rs. 50 per month, ensuring that individuals across various income levels can benefit from this coverage.
            </li>
            <li><strong>Basic Coverage:</strong> 
              The policy covers a variety of minor losses, including:
              <ul>
                <li>Loss of Car Keys</li>
                <li>Loss of Transcripts and Degrees</li>
                <li>Burglary of Smart Watches</li>
                <li>Loss of Passports</li>
                <li>Loss of CNICs</li>
                <li>Loss of House Registry</li>
              </ul>
            </li>
            <li><strong>Accessibility:</strong> 
              The entire insurance process, from purchase to claim management, is fully digitized. The product can be purchased and managed through a mini app, making it easy for policyholders to access insurance services anytime, anywhere.
            </li>
            <li><strong>Hassle-Free Claims:</strong> 
              Claims under this insurance plan are processed with minimal paperwork, ensuring a quick, efficient, and stress-free experience for the insured. The claim process is designed to be user-friendly and fast, removing the traditional barriers often associated with insurance claims.
            </li>
          </ol>

          <p><strong>Premium and Coverage Details:</strong></p>
          <ul>
            <li>Premium Amount: Starting from Rs. 100 per month</li>
            <li>All insurance policies operate on a recurring payment basis and can be unsubscribed at any time by calling the helpline or through the mini app.</li>
            <li>Coverage Limit: Based on the item insured (to be determined by policy specifics for each category of loss)</li>
          </ul>

          <p><strong>Exclusions:</strong></p>
          <ul>
            <li>Pre-existing damages or loss before policy activation</li>
            <li>Intentional damage or fraudulent claims</li>
            <li>Loss due to gross negligence (e.g., leaving items unattended in public places)</li>
            <li>Items not specifically listed in the insured category or under coverage limits</li>
          </ul>

          <p><strong>Claims Process:</strong></p>
          <ol>
            <li><strong>Report the Loss:</strong> 
              Policyholders can report any loss through the app/WhatsApp by submitting basic information and proof of the incident.
            </li>
            <li><strong>Required Documents:</strong> 
              Police report, receipts, or certifications of ownership, CNIC.
            </li>
            <li><strong>Claim Assessment:</strong> 
              The insurance team will assess the claim within 48 hours of submission.
            </li>
            <li><strong>Claim Approval and Payout:</strong> 
              Approved claims will be processed, and the payout will be made directly to the policyholder’s account within 5 working days after completion of documentation.
            </li>
          </ol>

          <p><strong>Eligibility & Enrolment:</strong></p>
          <ul>
            <li>Applicants are eligible to apply for this insurance Plan if they meet ALL the criteria set out below:</li>
            <li>All eligible applicants shall be natural persons. Corporations, partnerships, and businesses shall not be eligible for coverage under this Policy.</li>
            <li>All eligible applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
            <li>A waiting period of 7 days applies.</li>
          </ul>

          <p><strong>Mistake in Age:</strong></p>
          <p>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. The onus is on the customer for incorrect disclosure; the Company shall not be liable to pay any benefit under this Policy in that case.</p>

          <p><strong>Intentional False Statements of the Insured:</strong></p>
          <p>In the event of any concealment or misrepresentation, the insurance Policy shall become null and void with respect to the relevant Insured.</p>

          <p><strong>Notice of Claims:</strong></p>
          <p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN) days from the date of loss, which shall be treated as time-barred, and the Company shall not be bound to pay the Claim.</p>

          <p>Pricing for Products is variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting. The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>

          <p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance Company.</p>
        `,
        type: "insurance",
      },
      {
        id: 1214,
        image: "assets/MiniAppAssets/car.png",
        name: "Passport insurance",
        badge: "Monthly Premium",
        coverage: "50,000",
        price: "75",
        bgColorClass: "bg-warning",
        description: `
          <p><strong>Overview:</strong> 
          Pocket Size Insurance is a micro-insurance product offering affordable coverage for specific risks, including accidents and the loss of important documentation or personal items such as <strong>Car Keys, Transcripts, Degrees, Wearable Gadgets, Watches, Passports, CNIC</strong>. The product is designed to provide essential protection at a low premium with simple terms to enhance accessibility for a broad customer base.</p>
          
          <p><strong>Key Features:</strong></p>
          <ol>
            <li><strong>Low Premium:</strong> 
              The insurance plan is highly affordable, with premiums starting from as low as Rs. 50 per month, ensuring that individuals across various income levels can benefit from this coverage.
            </li>
            <li><strong>Basic Coverage:</strong> 
              The policy covers a variety of minor losses, including:
              <ul>
                <li>Loss of Car Keys</li>
                <li>Loss of Transcripts and Degrees</li>
                <li>Burglary of Smart Watches</li>
                <li>Loss of Passports</li>
                <li>Loss of CNICs</li>
                <li>Loss of House Registry</li>
              </ul>
            </li>
            <li><strong>Accessibility:</strong> 
              The entire insurance process, from purchase to claim management, is fully digitized. The product can be purchased and managed through a mini app, making it easy for policyholders to access insurance services anytime, anywhere.
            </li>
            <li><strong>Hassle-Free Claims:</strong> 
              Claims under this insurance plan are processed with minimal paperwork, ensuring a quick, efficient, and stress-free experience for the insured. The claim process is designed to be user-friendly and fast, removing the traditional barriers often associated with insurance claims.
            </li>
          </ol>

          <p><strong>Premium and Coverage Details:</strong></p>
          <ul>
            <li>Premium Amount: Starting from Rs. 100 per month</li>
            <li>All insurance policies operate on a recurring payment basis and can be unsubscribed at any time by calling the helpline or through the mini app.</li>
            <li>Coverage Limit: Based on the item insured (to be determined by policy specifics for each category of loss)</li>
          </ul>

          <p><strong>Exclusions:</strong></p>
          <ul>
            <li>Pre-existing damages or loss before policy activation</li>
            <li>Intentional damage or fraudulent claims</li>
            <li>Loss due to gross negligence (e.g., leaving items unattended in public places)</li>
            <li>Items not specifically listed in the insured category or under coverage limits</li>
          </ul>

          <p><strong>Claims Process:</strong></p>
          <ol>
            <li><strong>Report the Loss:</strong> 
              Policyholders can report any loss through the app/WhatsApp by submitting basic information and proof of the incident.
            </li>
            <li><strong>Required Documents:</strong> 
              Police report, receipts, or certifications of ownership, CNIC.
            </li>
            <li><strong>Claim Assessment:</strong> 
              The insurance team will assess the claim within 48 hours of submission.
            </li>
            <li><strong>Claim Approval and Payout:</strong> 
              Approved claims will be processed, and the payout will be made directly to the policyholder’s account within 5 working days after completion of documentation.
            </li>
          </ol>

          <p><strong>Eligibility & Enrolment:</strong></p>
          <ul>
            <li>Applicants are eligible to apply for this insurance Plan if they meet ALL the criteria set out below:</li>
            <li>All eligible applicants shall be natural persons. Corporations, partnerships, and businesses shall not be eligible for coverage under this Policy.</li>
            <li>All eligible applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
            <li>A waiting period of 7 days applies.</li>
          </ul>

          <p><strong>Mistake in Age:</strong></p>
          <p>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. The onus is on the customer for incorrect disclosure; the Company shall not be liable to pay any benefit under this Policy in that case.</p>

          <p><strong>Intentional False Statements of the Insured:</strong></p>
          <p>In the event of any concealment or misrepresentation, the insurance Policy shall become null and void with respect to the relevant Insured.</p>

          <p><strong>Notice of Claims:</strong></p>
          <p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN) days from the date of loss, which shall be treated as time-barred, and the Company shall not be bound to pay the Claim.</p>

          <p>Pricing for Products is variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting. The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>

          <p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance Company.</p>
        `,
        type: "insurance",
      },
      {
        id: 1215,
        image: "assets/MiniAppAssets/car.png",
        name: "CNIC Cover",
        badge: "Monthly Premium",
        coverage: "25,000",
        price: "50",
        bgColorClass: "bg-warning",
        description: `
          <p><strong>Overview:</strong> 
          Pocket Size Insurance is a micro-insurance product offering affordable coverage for specific risks, including accidents and the loss of important documentation or personal items such as <strong>Car Keys, Transcripts, Degrees, Wearable Gadgets, Watches, Passports, CNIC</strong>. The product is designed to provide essential protection at a low premium with simple terms to enhance accessibility for a broad customer base.</p>
          
          <p><strong>Key Features:</strong></p>
          <ol>
            <li><strong>Low Premium:</strong> 
              The insurance plan is highly affordable, with premiums starting from as low as Rs. 50 per month, ensuring that individuals across various income levels can benefit from this coverage.
            </li>
            <li><strong>Basic Coverage:</strong> 
              The policy covers a variety of minor losses, including:
              <ul>
                <li>Loss of Car Keys</li>
                <li>Loss of Transcripts and Degrees</li>
                <li>Burglary of Smart Watches</li>
                <li>Loss of Passports</li>
                <li>Loss of CNICs</li>
                <li>Loss of House Registry</li>
              </ul>
            </li>
            <li><strong>Accessibility:</strong> 
              The entire insurance process, from purchase to claim management, is fully digitized. The product can be purchased and managed through a mini app, making it easy for policyholders to access insurance services anytime, anywhere.
            </li>
            <li><strong>Hassle-Free Claims:</strong> 
              Claims under this insurance plan are processed with minimal paperwork, ensuring a quick, efficient, and stress-free experience for the insured. The claim process is designed to be user-friendly and fast, removing the traditional barriers often associated with insurance claims.
            </li>
          </ol>

          <p><strong>Premium and Coverage Details:</strong></p>
          <ul>
            <li>Premium Amount: Starting from Rs. 100 per month</li>
            <li>All insurance policies operate on a recurring payment basis and can be unsubscribed at any time by calling the helpline or through the mini app.</li>
            <li>Coverage Limit: Based on the item insured (to be determined by policy specifics for each category of loss)</li>
          </ul>

          <p><strong>Exclusions:</strong></p>
          <ul>
            <li>Pre-existing damages or loss before policy activation</li>
            <li>Intentional damage or fraudulent claims</li>
            <li>Loss due to gross negligence (e.g., leaving items unattended in public places)</li>
            <li>Items not specifically listed in the insured category or under coverage limits</li>
          </ul>

          <p><strong>Claims Process:</strong></p>
          <ol>
            <li><strong>Report the Loss:</strong> 
              Policyholders can report any loss through the app/WhatsApp by submitting basic information and proof of the incident.
            </li>
            <li><strong>Required Documents:</strong> 
              Police report, receipts, or certifications of ownership, CNIC.
            </li>
            <li><strong>Claim Assessment:</strong> 
              The insurance team will assess the claim within 48 hours of submission.
            </li>
            <li><strong>Claim Approval and Payout:</strong> 
              Approved claims will be processed, and the payout will be made directly to the policyholder’s account within 5 working days after completion of documentation.
            </li>
          </ol>

          <p><strong>Eligibility & Enrolment:</strong></p>
          <ul>
            <li>Applicants are eligible to apply for this insurance Plan if they meet ALL the criteria set out below:</li>
            <li>All eligible applicants shall be natural persons. Corporations, partnerships, and businesses shall not be eligible for coverage under this Policy.</li>
            <li>All eligible applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
            <li>A waiting period of 7 days applies.</li>
          </ul>

          <p><strong>Mistake in Age:</strong></p>
          <p>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. The onus is on the customer for incorrect disclosure; the Company shall not be liable to pay any benefit under this Policy in that case.</p>

          <p><strong>Intentional False Statements of the Insured:</strong></p>
          <p>In the event of any concealment or misrepresentation, the insurance Policy shall become null and void with respect to the relevant Insured.</p>

          <p><strong>Notice of Claims:</strong></p>
          <p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN) days from the date of loss, which shall be treated as time-barred, and the Company shall not be bound to pay the Claim.</p>

          <p>Pricing for Products is variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting. The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>

          <p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance Company.</p>
        `,
        type: "insurance",
      },
      {
        id: 1216,
        image: "assets/MiniAppAssets/car.png",
        name: "Educational Documents",
        badge: "Monthly Premium",
        coverage: "50,000",
        price: "100",
        bgColorClass: "bg-warning",
        description: `
          <p><strong>Overview:</strong> 
          Pocket Size Insurance is a micro-insurance product offering affordable coverage for specific risks, including accidents and the loss of important documentation or personal items such as <strong>Car Keys, Transcripts, Degrees, Wearable Gadgets, Watches, Passports, CNIC</strong>. The product is designed to provide essential protection at a low premium with simple terms to enhance accessibility for a broad customer base.</p>
          
          <p><strong>Key Features:</strong></p>
          <ol>
            <li><strong>Low Premium:</strong> 
              The insurance plan is highly affordable, with premiums starting from as low as Rs. 50 per month, ensuring that individuals across various income levels can benefit from this coverage.
            </li>
            <li><strong>Basic Coverage:</strong> 
              The policy covers a variety of minor losses, including:
              <ul>
                <li>Loss of Car Keys</li>
                <li>Loss of Transcripts and Degrees</li>
                <li>Burglary of Smart Watches</li>
                <li>Loss of Passports</li>
                <li>Loss of CNICs</li>
                <li>Loss of House Registry</li>
              </ul>
            </li>
            <li><strong>Accessibility:</strong> 
              The entire insurance process, from purchase to claim management, is fully digitized. The product can be purchased and managed through a mini app, making it easy for policyholders to access insurance services anytime, anywhere.
            </li>
            <li><strong>Hassle-Free Claims:</strong> 
              Claims under this insurance plan are processed with minimal paperwork, ensuring a quick, efficient, and stress-free experience for the insured. The claim process is designed to be user-friendly and fast, removing the traditional barriers often associated with insurance claims.
            </li>
          </ol>

          <p><strong>Premium and Coverage Details:</strong></p>
          <ul>
            <li>Premium Amount: Starting from Rs. 100 per month</li>
            <li>All insurance policies operate on a recurring payment basis and can be unsubscribed at any time by calling the helpline or through the mini app.</li>
            <li>Coverage Limit: Based on the item insured (to be determined by policy specifics for each category of loss)</li>
          </ul>

          <p><strong>Exclusions:</strong></p>
          <ul>
            <li>Pre-existing damages or loss before policy activation</li>
            <li>Intentional damage or fraudulent claims</li>
            <li>Loss due to gross negligence (e.g., leaving items unattended in public places)</li>
            <li>Items not specifically listed in the insured category or under coverage limits</li>
          </ul>

          <p><strong>Claims Process:</strong></p>
          <ol>
            <li><strong>Report the Loss:</strong> 
              Policyholders can report any loss through the app/WhatsApp by submitting basic information and proof of the incident.
            </li>
            <li><strong>Required Documents:</strong> 
              Police report, receipts, or certifications of ownership, CNIC.
            </li>
            <li><strong>Claim Assessment:</strong> 
              The insurance team will assess the claim within 48 hours of submission.
            </li>
            <li><strong>Claim Approval and Payout:</strong> 
              Approved claims will be processed, and the payout will be made directly to the policyholder’s account within 5 working days after completion of documentation.
            </li>
          </ol>

          <p><strong>Eligibility & Enrolment:</strong></p>
          <ul>
            <li>Applicants are eligible to apply for this insurance Plan if they meet ALL the criteria set out below:</li>
            <li>All eligible applicants shall be natural persons. Corporations, partnerships, and businesses shall not be eligible for coverage under this Policy.</li>
            <li>All eligible applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
            <li>A waiting period of 7 days applies.</li>
          </ul>

          <p><strong>Mistake in Age:</strong></p>
          <p>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. The onus is on the customer for incorrect disclosure; the Company shall not be liable to pay any benefit under this Policy in that case.</p>

          <p><strong>Intentional False Statements of the Insured:</strong></p>
          <p>In the event of any concealment or misrepresentation, the insurance Policy shall become null and void with respect to the relevant Insured.</p>

          <p><strong>Notice of Claims:</strong></p>
          <p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN) days from the date of loss, which shall be treated as time-barred, and the Company shall not be bound to pay the Claim.</p>

          <p>Pricing for Products is variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting. The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>

          <p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance Company.</p>
        `,
        type: "insurance",
      },
      {
        id: 1217,
        image: "assets/MiniAppAssets/car.png",
        name: "Smart watches",
        badge: "Monthly Premium",
        coverage: "100,000",
        price: "200",
        bgColorClass: "bg-warning",
        description: `
          <p><strong>Overview:</strong> 
          Pocket Size Insurance is a micro-insurance product offering affordable coverage for specific risks, including accidents and the loss of important documentation or personal items such as <strong>Car Keys, Transcripts, Degrees, Wearable Gadgets, Watches, Passports, CNIC</strong>. The product is designed to provide essential protection at a low premium with simple terms to enhance accessibility for a broad customer base.</p>
          
          <p><strong>Key Features:</strong></p>
          <ol>
            <li><strong>Low Premium:</strong> 
              The insurance plan is highly affordable, with premiums starting from as low as Rs. 50 per month, ensuring that individuals across various income levels can benefit from this coverage.
            </li>
            <li><strong>Basic Coverage:</strong> 
              The policy covers a variety of minor losses, including:
              <ul>
                <li>Loss of Car Keys</li>
                <li>Loss of Transcripts and Degrees</li>
                <li>Burglary of Smart Watches</li>
                <li>Loss of Passports</li>
                <li>Loss of CNICs</li>
                <li>Loss of House Registry</li>
              </ul>
            </li>
            <li><strong>Accessibility:</strong> 
              The entire insurance process, from purchase to claim management, is fully digitized. The product can be purchased and managed through a mini app, making it easy for policyholders to access insurance services anytime, anywhere.
            </li>
            <li><strong>Hassle-Free Claims:</strong> 
              Claims under this insurance plan are processed with minimal paperwork, ensuring a quick, efficient, and stress-free experience for the insured. The claim process is designed to be user-friendly and fast, removing the traditional barriers often associated with insurance claims.
            </li>
          </ol>

          <p><strong>Premium and Coverage Details:</strong></p>
          <ul>
            <li>Premium Amount: Starting from Rs. 100 per month</li>
            <li>All insurance policies operate on a recurring payment basis and can be unsubscribed at any time by calling the helpline or through the mini app.</li>
            <li>Coverage Limit: Based on the item insured (to be determined by policy specifics for each category of loss)</li>
          </ul>

          <p><strong>Exclusions:</strong></p>
          <ul>
            <li>Pre-existing damages or loss before policy activation</li>
            <li>Intentional damage or fraudulent claims</li>
            <li>Loss due to gross negligence (e.g., leaving items unattended in public places)</li>
            <li>Items not specifically listed in the insured category or under coverage limits</li>
          </ul>

          <p><strong>Claims Process:</strong></p>
          <ol>
            <li><strong>Report the Loss:</strong> 
              Policyholders can report any loss through the app/WhatsApp by submitting basic information and proof of the incident.
            </li>
            <li><strong>Required Documents:</strong> 
              Police report, receipts, or certifications of ownership, CNIC.
            </li>
            <li><strong>Claim Assessment:</strong> 
              The insurance team will assess the claim within 48 hours of submission.
            </li>
            <li><strong>Claim Approval and Payout:</strong> 
              Approved claims will be processed, and the payout will be made directly to the policyholder’s account within 5 working days after completion of documentation.
            </li>
          </ol>

          <p><strong>Eligibility & Enrolment:</strong></p>
          <ul>
            <li>Applicants are eligible to apply for this insurance Plan if they meet ALL the criteria set out below:</li>
            <li>All eligible applicants shall be natural persons. Corporations, partnerships, and businesses shall not be eligible for coverage under this Policy.</li>
            <li>All eligible applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
            <li>A waiting period of 7 days applies.</li>
          </ul>

          <p><strong>Mistake in Age:</strong></p>
          <p>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. The onus is on the customer for incorrect disclosure; the Company shall not be liable to pay any benefit under this Policy in that case.</p>

          <p><strong>Intentional False Statements of the Insured:</strong></p>
          <p>In the event of any concealment or misrepresentation, the insurance Policy shall become null and void with respect to the relevant Insured.</p>

          <p><strong>Notice of Claims:</strong></p>
          <p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN) days from the date of loss, which shall be treated as time-barred, and the Company shall not be bound to pay the Claim.</p>

          <p>Pricing for Products is variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting. The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>

          <p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance Company.</p>
        `,
        type: "insurance",
      },
      {
        id: 1218,
        image: "assets/MiniAppAssets/car.png",
        name: "Home Registry",
        badge: "Monthly Premium",
        coverage: "100,000",
        price: "200",
        bgColorClass: "bg-warning",
        description: `
          <p><strong>Overview:</strong> 
          Pocket Size Insurance is a micro-insurance product offering affordable coverage for specific risks, including accidents and the loss of important documentation or personal items such as <strong>Car Keys, Transcripts, Degrees, Wearable Gadgets, Watches, Passports, CNIC</strong>. The product is designed to provide essential protection at a low premium with simple terms to enhance accessibility for a broad customer base.</p>
          
          <p><strong>Key Features:</strong></p>
          <ol>
            <li><strong>Low Premium:</strong> 
              The insurance plan is highly affordable, with premiums starting from as low as Rs. 50 per month, ensuring that individuals across various income levels can benefit from this coverage.
            </li>
            <li><strong>Basic Coverage:</strong> 
              The policy covers a variety of minor losses, including:
              <ul>
                <li>Loss of Car Keys</li>
                <li>Loss of Transcripts and Degrees</li>
                <li>Burglary of Smart Watches</li>
                <li>Loss of Passports</li>
                <li>Loss of CNICs</li>
                <li>Loss of House Registry</li>
              </ul>
            </li>
            <li><strong>Accessibility:</strong> 
              The entire insurance process, from purchase to claim management, is fully digitized. The product can be purchased and managed through a mini app, making it easy for policyholders to access insurance services anytime, anywhere.
            </li>
            <li><strong>Hassle-Free Claims:</strong> 
              Claims under this insurance plan are processed with minimal paperwork, ensuring a quick, efficient, and stress-free experience for the insured. The claim process is designed to be user-friendly and fast, removing the traditional barriers often associated with insurance claims.
            </li>
          </ol>

          <p><strong>Premium and Coverage Details:</strong></p>
          <ul>
            <li>Premium Amount: Starting from Rs. 100 per month</li>
            <li>All insurance policies operate on a recurring payment basis and can be unsubscribed at any time by calling the helpline or through the mini app.</li>
            <li>Coverage Limit: Based on the item insured (to be determined by policy specifics for each category of loss)</li>
          </ul>

          <p><strong>Exclusions:</strong></p>
          <ul>
            <li>Pre-existing damages or loss before policy activation</li>
            <li>Intentional damage or fraudulent claims</li>
            <li>Loss due to gross negligence (e.g., leaving items unattended in public places)</li>
            <li>Items not specifically listed in the insured category or under coverage limits</li>
          </ul>

          <p><strong>Claims Process:</strong></p>
          <ol>
            <li><strong>Report the Loss:</strong> 
              Policyholders can report any loss through the app/WhatsApp by submitting basic information and proof of the incident.
            </li>
            <li><strong>Required Documents:</strong> 
              Police report, receipts, or certifications of ownership, CNIC.
            </li>
            <li><strong>Claim Assessment:</strong> 
              The insurance team will assess the claim within 48 hours of submission.
            </li>
            <li><strong>Claim Approval and Payout:</strong> 
              Approved claims will be processed, and the payout will be made directly to the policyholder’s account within 5 working days after completion of documentation.
            </li>
          </ol>

          <p><strong>Eligibility & Enrolment:</strong></p>
          <ul>
            <li>Applicants are eligible to apply for this insurance Plan if they meet ALL the criteria set out below:</li>
            <li>All eligible applicants shall be natural persons. Corporations, partnerships, and businesses shall not be eligible for coverage under this Policy.</li>
            <li>All eligible applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
            <li>A waiting period of 7 days applies.</li>
          </ul>

          <p><strong>Mistake in Age:</strong></p>
          <p>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. The onus is on the customer for incorrect disclosure; the Company shall not be liable to pay any benefit under this Policy in that case.</p>

          <p><strong>Intentional False Statements of the Insured:</strong></p>
          <p>In the event of any concealment or misrepresentation, the insurance Policy shall become null and void with respect to the relevant Insured.</p>

          <p><strong>Notice of Claims:</strong></p>
          <p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN) days from the date of loss, which shall be treated as time-barred, and the Company shall not be bound to pay the Claim.</p>

          <p>Pricing for Products is variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting. The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>

          <p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance Company.</p>
        `,
        type: "insurance",
      },
    ];

    this.terms = `
    <p><strong>Overview:</strong> 
          Pocket Size Insurance is a micro-insurance product offering affordable coverage for specific risks, including accidents and the loss of important documentation or personal items such as <strong>Car Keys, Transcripts, Degrees, Wearable Gadgets, Watches, Passports, CNIC</strong>. The product is designed to provide essential protection at a low premium with simple terms to enhance accessibility for a broad customer base.</p>
          
          <p><strong>Key Features:</strong></p>
          <ol>
            <li><strong>Low Premium:</strong> 
              The insurance plan is highly affordable, with premiums starting from as low as Rs. 50 per month, ensuring that individuals across various income levels can benefit from this coverage.
            </li>
            <li><strong>Basic Coverage:</strong> 
              The policy covers a variety of minor losses, including:
              <ul>
                <li>Loss of Car Keys</li>
                <li>Loss of Transcripts and Degrees</li>
                <li>Burglary of Smart Watches</li>
                <li>Loss of Passports</li>
                <li>Loss of CNICs</li>
                <li>Loss of House Registry</li>
              </ul>
            </li>
            <li><strong>Accessibility:</strong> 
              The entire insurance process, from purchase to claim management, is fully digitized. The product can be purchased and managed through a mini app, making it easy for policyholders to access insurance services anytime, anywhere.
            </li>
            <li><strong>Hassle-Free Claims:</strong> 
              Claims under this insurance plan are processed with minimal paperwork, ensuring a quick, efficient, and stress-free experience for the insured. The claim process is designed to be user-friendly and fast, removing the traditional barriers often associated with insurance claims.
            </li>
          </ol>

          <p><strong>Premium and Coverage Details:</strong></p>
          <ul>
            <li>Premium Amount: Starting from Rs. 100 per month</li>
            <li>All insurance policies operate on a recurring payment basis and can be unsubscribed at any time by calling the helpline or through the mini app.</li>
            <li>Coverage Limit: Based on the item insured (to be determined by policy specifics for each category of loss)</li>
          </ul>

          <p><strong>Exclusions:</strong></p>
          <ul>
            <li>Pre-existing damages or loss before policy activation</li>
            <li>Intentional damage or fraudulent claims</li>
            <li>Loss due to gross negligence (e.g., leaving items unattended in public places)</li>
            <li>Items not specifically listed in the insured category or under coverage limits</li>
          </ul>

          <p><strong>Claims Process:</strong></p>
          <ol>
            <li><strong>Report the Loss:</strong> 
              Policyholders can report any loss through the app/WhatsApp by submitting basic information and proof of the incident.
            </li>
            <li><strong>Required Documents:</strong> 
              Police report, receipts, or certifications of ownership, CNIC.
            </li>
            <li><strong>Claim Assessment:</strong> 
              The insurance team will assess the claim within 48 hours of submission.
            </li>
            <li><strong>Claim Approval and Payout:</strong> 
              Approved claims will be processed, and the payout will be made directly to the policyholder’s account within 5 working days after completion of documentation.
            </li>
          </ol>

          <p><strong>Eligibility & Enrolment:</strong></p>
          <ul>
            <li>Applicants are eligible to apply for this insurance Plan if they meet ALL the criteria set out below:</li>
            <li>All eligible applicants shall be natural persons. Corporations, partnerships, and businesses shall not be eligible for coverage under this Policy.</li>
            <li>All eligible applicants shall be a minimum of eighteen (18) years of age and a maximum of fifty-nine (59) years of age at the time of registration.</li>
            <li>A waiting period of 7 days applies.</li>
          </ul>

          <p><strong>Mistake in Age:</strong></p>
          <p>The Company shall only pay Insurance Benefits based on the disclosed age of the Insured. The onus is on the customer for incorrect disclosure; the Company shall not be liable to pay any benefit under this Policy in that case.</p>

          <p><strong>Intentional False Statements of the Insured:</strong></p>
          <p>In the event of any concealment or misrepresentation, the insurance Policy shall become null and void with respect to the relevant Insured.</p>

          <p><strong>Notice of Claims:</strong></p>
          <p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN) days from the date of loss, which shall be treated as time-barred, and the Company shall not be bound to pay the Claim.</p>

          <p>Pricing for Products is variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting. The insurance policy is set to automatically renew on a monthly basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>

          <p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance Company.</p>
  `;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default PocketInsurance;
