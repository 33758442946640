import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsDownload } from "react-icons/bs";
import { Container, Row, Col } from "react-bootstrap";
import Header_packages from "../components/Header_packages";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useLoading } from "../contexts/LoadingContext";
import "./prescription.css";

const Prescription = () => {
  const [prescriptionData, setPrescriptionData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const componentRef = useRef();
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://testingnew.webddocsystems.com/public/api/v1/prescription/view?consultationId=${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to prescription data");
        }
        const data = await response.json();
        if (data.statusCode === 1 && data.payLoad) {
          setPrescriptionData(data.payLoad);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error prescription data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, setIsLoading]);

  const downloadPdf = async () => {
    try {
      const element = componentRef.current;
      const canvas = await html2canvas(element, {
        scrollY: -window.scrollY,
      });
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "px",
        format: "a4",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth - 20;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      const headerText = "Prescription Details";
      const margin = 20;
      pdf.text(headerText, 10, 10);
      pdf.addImage(imgData, "JPEG", 10, margin, imgWidth, imgHeight);
      heightLeft -= pdfHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 10, position + 10, imgWidth, imgHeight);
        heightLeft -= pdfHeight;
      }

      pdf.save("prescription.pdf");
    } catch (error) {
      console.error("Error generating PDF", error);
    }
  };

  return (
    <Container fluid className="overflow-auto">
      <Header_packages title="Prescription Details"/>
      <div className="mt-2">
        <Row className="p-2" style={{ marginBottom: "100px" }}>
          <div ref={componentRef}>
            <section className="inner-frame1">
              <div className="sub-frame-a1">
                <div className="now-previous-frames">
                  <div className="video-call-frame1">
                    <Col xs={12}>
                      <div className="a-f-b-myco-frames">
                        <Row xs={12}>
                          <Col xs={4}>
                            <p className="diagnosis1">Doctor</p>
                            <p className="diagnosis1">Data</p>
                            <p className="diagnosis1">Complaint</p>
                            <p className="diagnosis1">Diagnosis</p>
                          </Col>
                          <Col xs={8}>
                            {prescriptionData && (
                              <>
                                <p className="diagnosisClass">
                                  {prescriptionData.DoctorFullName}
                                </p>
                                <p className="diagnosisClass">
                                  {prescriptionData.ConsultationDate}
                                </p>
                                <p className="diagnosisClass">
                                  {prescriptionData.Complaint}
                                </p>
                                <p className="diagnosisClass">
                                  {prescriptionData.Diagnosis}
                                </p>
                              </>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    {prescriptionData &&
                      prescriptionData.Consultationdetails.map((value) => (
                        <div
                          className="complaint-consultation-test-fr"
                          key={value.Id}
                        >
                          <Row xs={12}>
                            <Col xs={4}>
                              <p className="diagnosis1">MedicineName</p>
                              <p className="diagnosis1">Days</p>
                            </Col>
                            <Col xs={8}>
                              <p className="diagnosisClass">
                                {value.MedicineName}
                              </p>
                              <p className="diagnosisClass">{value.Days}</p>
                            </Col>
                            <Row xs={12}>
                              <Col xs={4}>
                                <p className="noon">Morning</p>
                              </Col>
                              <Col xs={4}>
                                <p className="noon">Noon</p>
                              </Col>
                              <Col xs={4}>
                                <p className="noon">Evening</p>
                              </Col>
                            </Row>
                            <Row xs={12} className="mt-2">
                              <Col xs={4}>
                                <p className="custom-margin">
                                  {value?.Morning ?? 0}
                                </p>
                              </Col>
                              <Col xs={4}>
                                <p className="custom-margin">
                                  {value?.Day ?? 0}
                                </p>
                              </Col>
                              <Col xs={4}>
                                <p className="custom-margin">
                                  {value?.Night ?? 0}
                                </p>
                              </Col>
                            </Row>
                            <Row xs={12}>
                              <Col xs={2}>
                                <p className="diagnosis1 text-green">Note:</p>
                              </Col>
                              <Col xs={10}>
                                <p className="diagnosisClass">
                                  {value.AdditionalNotes}
                                </p>
                              </Col>
                            </Row>
                          </Row>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-4">
            <BsDownload onClick={downloadPdf} className="navbar-icon" />
          </div>
        </Row>
      </div>
    </Container>
  );
};

export default Prescription;
