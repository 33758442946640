// src/pages/InsuranceScreen.js
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../pages/Paymentscreen.css";
import Header_packages from "../components/Header_packages";
import InsuranceComponent from "../components/InsuranceComponent";
import getInsuranceData from "../modals/getInsuranceData";
import PackageScreen from "../components/pacakge-screen";
import { useLoading } from "../contexts/LoadingContext";
import ActivatedPackageComponent from "../components/activatedPackage";

const InsuranceScreen = () => {
  const session = JSON.parse(sessionStorage.getItem("user"));
  const customerNumber = session.userMsisdn;
  // console.log(customerNumber);
  const { type, id } = useParams();

  const isMobile = type === "mobile";
  const isHealth = type === "health";
  const isIncome = type === "income";
  const isAccidental = type === "accidental";
  const isWomen = type === "women";
  const isCrops = type === "crops";
  const isLivestock = type === "livestock";
  const isAgri = type === "agri";
  const isVet = type === "vets";
  const isBike = type === "bike";
  const isCar = type === "car";
  const isPocket = type === "pocket";

  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isLoading, setIsLoading } = useLoading();

  let service = "insurance";

  if (type == "bike" || type == "car") {
    service = "insurance";
  } else if (type == "agri" || type == "vets") {
    service = type;
  }

  useEffect(() => {
    if (!type || !id) {
      return;
    }

    const fetchSubscriptionStatus = async () => {
      setIsLoading(true);
      //console.log(`https://insuranceservice.webddocsystems.com/api/MiniApp/CheckCategoryPackages?id=${id}&customerNumber=${customerNumber}`);
      try {
        const response = await fetch(
          `https://insuranceservice.webddocsystems.com/api/MiniApp/CheckCategoryPackages?id=${id}&customerNumber=${customerNumber}&service=${service}`,
          {
            method: "GET",
            headers: {
              Authorization:
                "Basic ZlJqZGZVbWFzOmFmMzNhZmNhODAwNzZkOWMxMTViOGU0NjAyZjc4YTIx",
            },
          }
        );
        const data = await response.json();
        console.log(data);
        setDetails(data);
      } catch (error) {
        //setIsLoading(false);
        console.error("Error fetching subscription status:", error);
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, [type, id]);

  const { items, terms } = getInsuranceData(type);
  if (loading) {
  } else if (details.responseCode === "0000") {
    // console.log(details);
    details.type = type;
    // details.Coverage = "30,000";
    return (
      <div>
        <Header_packages
          title={`${type.charAt(0).toUpperCase() + type.slice(1)} Package`}
        />
        <ActivatedPackageComponent items={details}/>
      </div>
    );
  } else {
    if (isMobile) {
      return (
        <div>
          <Header_packages
            title={`<span style="color:#25b45f; font-weight:600">Phone Hai</span> To Sub Kuch Hai`}
          />
          <InsuranceComponent items={items} terms={terms} />
        </div>
      );
    } else {
      if (isHealth) {
        return (
          <div>
            <Header_packages
              title={`<span style="color:#25b45f; font-weight:600">Tandarusti</span> Hazar Nemat Hai`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isIncome) {
        return (
          <div>
            <Header_packages
              title={`Mehfooz<span style="color:#25b45f; font-weight:600"> Amdan</span>  Khuhsal Gharana`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isAccidental) {
        return (
          <div>
            <Header_packages
              title={`<span style="color:#25b45f; font-weight:600">Mahfooz</span> Mustaqbil`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isWomen) {
        return (
          <div>
            <Header_packages
              title={`Sehat Har <span style="color:#25b45f; font-weight:600">Aurat k Liye</span>`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isCrops) {
        return (
          <div>
            <Header_packages
              title={`<span style="color:#25b45f; font-weight:600">Meri Fasal</span> Meri Jan`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isLivestock) {
        return (
          <div>
            <Header_packages
              title={`Humari Tarjee <span style="color:#25b45f; font-weight:600">Sehatmand Maveshi</span>`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isAgri) {
        return (
          <div>
            <Header_packages
              title={`<span style="color:#25b45f; font-weight:600">Agriculture</span> Experts`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isVet) {
        return (
          <div>
            <Header_packages
              title={`<span style="color:#25b45f; font-weight:600">Veterinary</span> Doctor`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isBike) {
        return (
          <div>
            <Header_packages
              title={`<span style="color:#25b45f; font-weight:600">Bike</span> Insurance`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isCar) {
        return (
          <div>
            <Header_packages
              title={`<span style="color:#25b45f; font-weight:600">Car</span> Insurance`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      } else if (isPocket) {
        return (
          <div>
            <Header_packages
              title={`<span style="color:#25b45f; font-weight:600">Pocket</span> Size Insurance`}
            />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      }
      else {
        return (
          <div>
            <Header_packages title={`Call A Doc`} />
            <PackageScreen items={items} terms={terms} />
          </div>
        );
      }
    }
  }
};

export default InsuranceScreen;
