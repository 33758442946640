import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import DoctorsList from "./pages/doctors-list";
import VideoCall from "./pages/video-call";
import Packages from "./pages/packages";
import PrescriptionsList from "./pages/prescriptions-list";
import Prescription from "./pages/prescription";
import Swal from "sweetalert2";
import "./App.css";
import { LoadingProvider } from "./contexts/LoadingContext";
import Loader from "./components/loader";
import InsuranceScreen from "./pages/InsuranceScreen";
import PaymentConfirm from "./pages/payment-confirm";
import MyActivePackages from "./pages/my-packages";
import TransactionList from "./pages/transaction-list";
import DoctorProfile from "./pages/doctor-profile";
import StepRangeSlider from "./pages/range-slider";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const ActivePackageFilter = ({ children }) => {
    const session = JSON.parse(sessionStorage.getItem("user"));
    const navigate = useNavigate();

    useEffect(() => {
      if (!session?.package) {
        Swal.fire({
          icon: "info",
          title: "Package Subscription",
          text: "Sorry, you do not have an active subscription. Please subscribe to a package to access.",
          showCancelButton: true,
          confirmButtonText: "Buy Package",
          cancelButtonText: "Back",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/insurancescreen/calladoc/70");
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            navigate(-1);
          }
        });
      }
    }, [session, navigate]);

    return session && session.package ? children : null;
  };

  const ActiveProtectedRoute = ({ children }) => {
    const session = JSON.parse(sessionStorage.getItem("userData"));
    const navigate = useNavigate();

    useEffect(() => {
      if (session?.package) {
        Swal.fire({
          icon: "info",
          title: "Subscription",
          text: "You already have an active subscription.",
          confirmButtonText: "OK",
        }).then(() => {
          navigate(-1);
        });
      }
    }, [session, navigate]);

    return !session?.package ? children : null;
  };

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home page";
        metaDescription = "This is home page.";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  // const Auth = () => {
  //   window.location.href = "https://demo.webdoc.com.pk/auth.php";
  //   return null;
  // };

  return (
    <LoadingProvider>
      <Loader />
      <Routes>
        <Route path="/" element={<Home />}/>
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path="/doctors-list" element={<DoctorsList />} />
        <Route
          path="/video-call"
          element={<ActivePackageFilter>{<VideoCall />}</ActivePackageFilter>}
        />
        <Route path="/packages" element={<Packages />} />
        <Route path="/prescriptions-list" element={<PrescriptionsList />} />
        <Route path="/prescription/:id" element={<Prescription />} />
        <Route path="/insurancescreen/:type/:id" element={<InsuranceScreen />} />
        {/* <Route path="/SubscribedPackage_Details" element={<SubscribedPackage_Details />} /> */}
        <Route path="/confirm" element={<PaymentConfirm />} />
        <Route path="/my-packages" element={<MyActivePackages />} />
        <Route path="/my-transaction" element={<TransactionList />} />
        <Route path="/doctor-profile/:id" element={<DoctorProfile />} />

        <Route path="/range-slider" element={<StepRangeSlider />} />


      </Routes>
    </LoadingProvider>
  );
}

export default App;
