class live_stock {
  constructor() {
    this.items = [
      {
        id: 187,
        name: "Daily Plan Basic",
        badge: "Basic",
        coverage: "20,000",
        price: "4",
        bgColorClass: "bg-info",
        description: `<p><strong>Simple Online Registration:</strong> Safeguard your livestock&rsquo;s future with quick and easy insurance enrolment.</p>
<ul>
<li><strong>Health Treatment Coverage: </strong>Up to Rs. 20,000 on a pro-rata basis</li>
<li><strong>Insurance Period: </strong>30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Hassle-Free Claims Processing:</strong> Enjoy a seamless experience with our efficient and supportive claims team.</p>`,
        type: "insurance",
      },
      {
        id: 190,
        name: "Daily Plan Silver",
        badge: "Silver",
        coverage: "40,000",
        price: "8",
        bgColorClass: "bg-warning",
        description: `<p><strong>Simple Online Registration:</strong> Safeguard your livestock&rsquo;s future with quick and easy insurance enrolment.</p>
<ul>
<li><strong>Health Treatment Coverage: </strong>Up to Rs. 40,000 on a pro-rata basis</li>
<li><strong>Insurance Period: </strong>30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Hassle-Free Claims Processing:</strong> Enjoy a seamless experience with our efficient and supportive claims team.</p>`,
        type: "insurance",
      },
      {
        id: 193,
        name: "Daily Plan Gold",
        badge: "Gold",
        coverage: "60,000",
        price: "12",
        bgColorClass: "bg-info",
        description: `<p><strong>Simple Online Registration:</strong> Safeguard your livestock&rsquo;s future with quick and easy insurance enrolment.</p>
<ul>
<li><strong>Health Treatment Coverage: </strong>Up to Rs. 60,000 on a pro-rata basis</li>
<li><strong>Insurance Period: </strong>30 days</li>
<li><strong>Subscription:</strong> Daily</li>
</ul>
<p><strong>Hassle-Free Claims Processing:</strong> Enjoy a seamless experience with our efficient and supportive claims team.</p>`,
        type: "insurance",
      },
    ];

    this.terms = `
  <p><strong>Key Policy Terms</strong></p>
  <ul>
    <li>Customer MSISDN required for policy generation</li>
    <li>Waiting period of 7 days is applicable</li>
    <li>Only one subscription per phone and per person</li>
    <li>Claim should be made within 15 days of hospitalization</li>
  </ul>

  <p><strong>Exclusions</strong></p>
  <ul>
    <li>Pre-existing conditions are not covered.</li>
    <li>General OPD is covered; prescriptions and bills must be paid within limits.</li>
    <li>Natural or accidental deaths are not covered.</li>
    <li>One phone number can have only one insurance.</li>
    <li>For procedures, prescriptions are required, and access costs Rs. 3000/-.</li>
    <li>50% cost of vaccinations is covered.</li>
    <li>Pregnancies and births are not covered.</li>
    <li>Payment of claims is subject to exclusions, including deaths in war, strikes, riots, civil commotion, natural or man-made perils (including chemical, nuclear, biological, and radiological), lack of initial pictures or mismatched pictures, and any other reasons deemed by the insurance company as valid for non-payment.</li>
    <li>Livestock involved in fighting, racing, or similar events are excluded from the policy.</li>
  </ul>

  <p><strong>Eligibility & Enrolment</strong></p>
  <p>Applicants are eligible to apply for a Livestock Insurance Plan under the Policy if they meet all of the following criteria:</p>
  <ol>
    <li>All eligible applicants must be natural persons. Corporations, partnerships, and businesses are not eligible.</li>
    <li>All eligible livestock must be healthier and younger than their average age over the last 2 years.</li>
  </ol>
  
  <p>If the Applicant wishes to apply for and subscribe to a Plan under this Insurance Policy, the Applicant will be required during the registration process to:</p>
  <ul>
    <li>Acknowledge that the Applicant has read and understood the terms of the Policy.</li>
    <li>Confirm that the Applicant meets the eligibility criteria.</li>
    <li>Confirm that all statements made by the Applicant are true and correct; otherwise, no Insurance Benefits will be payable, and the Subscriber's payment will not be refunded.</li>
  </ul>

  <p><strong>Intentional False Statements of The Insured</strong></p>
  <p>In the event of any concealment or misrepresentation, the Policy shall become null and void with respect to the relevant Insured.</p>
`;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default live_stock;
