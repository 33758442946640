import "./doctore-experience-frame.css";

const FrameComponent1 = ({ data }) => {
  // Check if data exists and if AverageRating is a number
  const averageRating =
    data && typeof data.AverageRating === "number" ? data.AverageRating.toFixed(1) : '';

  return (
    <div className="rectangle-parent16">
      <div className="frame-child34" />
      <div className="experience-box">
        <div className="experience1">Experience</div>
        <div className="parent-frame">
          <div className="years">{data ? data.Experience : ''}</div>
        </div>
      </div>
      <div className="about-label-wrapper">
        <div className="about-label" />
      </div>
      <div className="frame-wrapper7">
        <div className="ratings-parent">
          <div className="ratings">Ratings</div>
          <div className="div">{averageRating}</div>
        </div>
      </div>
      <div className="line-wrapper">
        <div className="frame-child35" />
      </div>
      <div className="call-to-action-frame">
        <div className="wait-time-wrapper">
          <div className="wait-time">Qualifications</div>
        </div>
        <div className="mins">{data ? data.DetailQualification : ''}</div>
      </div>
    </div>
  );
};

export default FrameComponent1;
