class mobile_insurance {
  constructor() {
    this.items = [
      // {
      //   id: 186,
      //   name: "Daily Plan Basic",
      //   badge: "Daily",
      //   coverage: "upto 10,000",
      //   price: "5",
      //   bgColorClass: "bg-info",
      //   description: `<p><strong>Instant Protection:</strong> Safeguard your phone in seconds with our easy online sign-up, just a click and you&rsquo;re covered!</p>
      //   <p><strong>Coverage: </strong>All-Inclusive Security: Rest easy with excellent coverage against:</p>
      //   <ul>
      //   <li>Theft</li>
      //   <li>Damage</li>
      //   </ul>
      //   <p><strong>Swift Claims:</strong> Experience hassle-free claims processing through our friendly support team and instant&nbsp;payouts.</p>
      //   <p>Stay connected, stay insured, and never worry about losing your digital lifeline again</p>`,
      //   type: "insurance",
      // },
      {
        id: 189,
        name: "Daily Plan Silver",
        badge: "Monthly",
        coverage: "20,000",
        price: "10",
        bgColorClass: "bg-warning",
        description: `<p><strong>Instant Protection:</strong> Safeguard your phone in seconds with our easy online sign-up, just a click and you&rsquo;re covered!. Premium is for an annum paid monthly, Claims are pro-rata basis.</p>
        <p><strong>Coverage: </strong>All-Inclusive Security: Rest easy with excellent coverage against:</p>
        <ul>
        <li>Theft</li>
        <li>Burglary</li>
        </ul>
        <p><strong>Swift Claims:</strong> Experience hassle-free claims processing through our friendly support team and instant&nbsp;payouts.</p>
        <p>Stay connected, stay insured, and never worry about losing your digital lifeline again</p>`,
        type: "insurance",
      },
      {
        id: 192,
        name: "Daily Plan Gold",
        badge: "Yearly",
        coverage: "30,000",
        price: "15",
        bgColorClass: "bg-info",
        description: `<p><strong>Instant Protection:</strong> Safeguard your phone in seconds with our easy online sign-up, just a click and you&rsquo;re covered!. Premium is for an annum paid monthly, Claims are pro-rata basis.</p>
        <p><strong>Coverage: </strong>All-Inclusive Security: Rest easy with excellent coverage against:</p>
        <ul>
        <li>Theft</li>
        <li>Burglary</li>
        </ul>
        <p><strong>Swift Claims:</strong> Experience hassle-free claims processing through our friendly support team and instant&nbsp;payouts.</p>
        <p>Stay connected, stay insured, and never worry about losing your digital lifeline again</p>`,
        type: "insurance",
      },
    ];
    this.terms = `<p><strong>Key Policy Terms &amp; Exclusions</strong></p>
<ul>
<li>Only one subscription per phone and per person at a time</li>
<li>Claim should be made within 15 days for damage &amp; theft.</li>
<li>SIM/MSISDN should be in the name of the claimant.</li>
</ul>
<p><strong>Exclusions</strong></p>
<p>Payment of claims is subject to exclusions, as outlined below. Mobile Insurance shall not cover.</p>
<ol>
<li>Any Pre-damaged Screen.</li>
<li>Already Lost or Stolen phones.</li>
<li>Forcedly or purposely broken phones.</li>
</ol>
<ol start="4">
<li>Customer Account numbers and Phone IMEI required.</li>
<li>A waiting period of 7 days is applicable.</li>
<li>Only one subscription per phone and per person. After claiming, insurance will be terminated.</li>
<li>Claim should be made within 15 days of damage.</li>
<li>Theft &amp; Burglary are covered &ndash; First investigation report Required.</li>
<li>Coverage is for new as well as old phones on a pro rata basis.</li>
<li>Non-PTA phones are not covered.</li>
</ol>
<p><strong>Eligibility &amp; Enrolment:</strong></p>
<ul>
<li>Applicants are eligible to apply for a Mobile Insurance Plan under the Policy if Applicants meet all the criteria set out below:</li>
<li>All Eligible Applicants shall be natural persons. Corporations, partnerships and businesses shall not be eligible for coverage under the Policy.</li>
<li>All Eligible Applicants shall be a minimum of eighteen (18) years of age.</li>
<li>If the Applicant wishes to apply for and subscribe to a Plan under this Insurance Policy, the Applicant will be required during the registration process to:</li>
<li>acknowledge that the Applicant has read and understood the terms of the Policy.</li>
<li>Confirm that the Applicant meets the eligibility criteria.</li>
<li>The Insurance Benefits payable are subject to the Applicant&rsquo;s confirmations being true and correct; and</li>
<li>If the Applicant&rsquo;s confirmation is untrue or incorrect, no Insurance Benefits will be payable, and the End User Price the Subscriber paid will not be refunded.</li>
<li>After purchasing the Insurance Policy, you need to send IMEI number along with your MSISDN number to the given WhatsApp. This should be a picture of the IMEI number taken from your phone. Only IMEI number is not acceptable. Alternatively, you may contact the helpline and register your IMEI number against your MSISDN number to be eligible for the claim.</li>
<li>A waiting period of 7 days applies.</li>
</ul>
<p><strong>Notice Of Claims:</strong></p>
<p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN days) from the date of damage or theft, which shall be treated as time‑barred and the Company shall not be bound to pay the Claim.</p>
<p><strong>Required Documents/Detail for claims:</strong></p>
<p>For each Claim reported, the Company shall obtain From the Claimant:</p>
<ul>
<li>CNIC copy Both Sides</li>
<li>Picture of IMEI Number of the phone or pre-registration before the event of claim</li>
<li>Police Report &ndash; In case of theft</li>
<li>Any Original Proper Bills to be claimed in case of damaged phone. &ndash; Damaged phone Only</li>
</ul>
<p>If there is a dispute, suspected fraudulent activity on the claim or a unique situation which requires further clarification, the payment period can be extended but shall not exceed ten (10) working days, or as long as the dispute takes to resolve in the legal system.</p>
<p><strong>Termination Of Individual Insurance</strong></p>
<ul>
<li>The insurance of an Insured shall automatically terminate at the earliest time below:</li>
<li>Upon payment of the claim or</li>
<li>Upon cancellation or withdrawal of subscription</li>
</ul>
<p>Pricing for Products are variable and may be modified at any point in time with mutual consent. All price changes shall take effect immediately upon posting.</p>
<p>The insurance policy is set to automatically renew on a monthly and annually basis for the next five (5) years. Policyholders can unsubscribe anytime.</p>
<p><strong>Disclaimer:</strong> This insurance is underwritten by East West Insurance company.</p>`;
  }

  // this.terms = `<p><strong>Key Policy Terms & Exclusions</strong></p>
  // <ul>
  //   <li>Only one subscription per phone and per person at a time</li>
  //   <li>Claim should be made within 15 days for damage & theft</li>
  //   <li>SIM/MSISDN should be in the name of the claimant</li>
  //   <li>Any Pre-damaged Screen.</li>
  //   <li>Already Lost or Stolen phones.</li>
  //   <li>Forcedly or purposely broken phones.</li>
  //   <li>Customer Account numbers and Phone IMEI required</li>
  //   <li>Waiting period of 7 days is applicable</li>
  //   <li>Only one subscription per phone and per person. After claim, insurance will be terminated</li>
  //   <li>Claim should be made within 15 days of damage</li>
  //   <li>Theft & Burglary are covered – First investigation report Required</li>
  //   <li>Coverage is for new as well as old phones on pro rata basis</li>
  //   <li>Non PTA phones are not covered</li>
  // </ul>
  // <p><strong>Eligibility & Enrolment:</strong></p>
  // <ul>
  //   <li>Applicants are eligible to apply for a Mobile Insurance Plan under the Policy if Applicants meet all the criteria set out below:</li>
  //   <li>All Eligible Applicants shall be natural persons. Corporations, partnerships and businesses shall not be eligible for coverage under the Policy.</li>
  //   <li>All Eligible Applicants shall be a minimum of eighteen (18) years of age.</li>
  //   <li>If the Applicant wishes to apply for and subscribe to a Plan under this Insurance Policy, the Applicant will be required during the registration process to:</li>
  //   <li>Acknowledge that the Applicant has read and understood the terms of the Policy.</li>
  //   <li>Confirm that the Applicant meets the eligibility criteria.</li>
  //   <li>The Insurance Benefits payable are subject to the Applicant’s confirmations being true and correct; and</li>
  //   <li>If the Applicant’s confirmation is untrue or incorrect, no Insurance Benefits will be payable, and the End User Price the Subscriber paid will not be refunded.</li>
  //   <li>After purchasing the Insurance Policy, you need to send IMEI number along with your MSISDN number to given WhatsApp. This should be a picture of the IMEI number taken from your phone. Only IMEI number is not acceptable. Alternatively, you may contact the helpline and register your IMEI number against your MSISDN number to be eligible for the claim.</li>
  //   <li>Waiting period of 7 days apply.</li>
  // </ul>
  // <p><strong>Notice Of Claims:</strong></p>
  // <p>The Company shall be notified of the occurrence of the Insured Claim as soon as possible, but not later than 15 (FIFTEEN days) from the date of damage or theft, which shall be treated as time barred and the Company shall not be bound to pay the Claim.</p>
  // <p><strong>Required Documents/Detail for claims:</strong></p>
  // <p>For each Claim reported, the Company shall obtain From the Claimant:</p>
  // <ol>
  //   <li>CNIC copy Both Sides</li>
  //   <li>Picture of IMEI Number of the phone or pre-registration before the event of claim</li>
  //   <li>Police Report – In case of theft</li>
  //   <li>Any Original Proper Bills to be claimed in case of damaged phone. – Damaged phone Only</li>
  // </ol>
  // <p>If there is a dispute, suspected fraudulent activity on the claim or a unique situation which requires further clarification, the payment period can be extended but shall not exceed ten (10) working days, or as long as the dispute takes to resolve in the legal system.</p>
  // <p><strong>Termination Of Individual Insurance</strong></p>
  // <p>The insurance of an Insured shall automatically terminate at the earliest time below:</p>
  // <ul>
  //   <li>Upon payment of the claim or</li>
  //   <li>Upon cancellation or withdrawal of subscription</li>
  // </ul>`;

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default mobile_insurance;
