import React from "react";
import Insurancecard from "./Insurancecard";
import { Link } from "react-router-dom";
import Bike_carinsurance from "./Bike_carinsurance";

const Insurance = () => {
  return (
    <div className="row mb-1">
    <div className="col-6">
      <Link to="/insurancescreen/bike/1021" style={{textDecoration:'none', color:'black'}}>
        <Bike_carinsurance
          image="assets/NewMiniapp/bikeimg.png"
          title="Bike Insurance"
        />
      </Link>
    </div>
    <div className="col-6">
      <Link to="/insurancescreen/car/1022" style={{textDecoration:'none', color:'black'}}>
        <Bike_carinsurance
          image="assets/NewMiniapp/carimg.png"
          title="Car Insurance"
        />
      </Link>
    </div>
  </div>
  );
};
export default Insurance;
