import React from "react";
import { Hearts } from "react-loader-spinner";
import { useLoading } from "../contexts/LoadingContext";
import "./loader.css";

const Loader = () => {
  const { isLoading } = useLoading();

  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader-blur" />
          <Hearts
            height="180"
            width="180"
            color="#51b886"
            ariaLabel="hearts-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default Loader;
