import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./range-slider.css";

const StepRangeSlider = () => {
  const [rangeValue, setRangeValue] = useState(30000);
  const [yearlyAmount, setYearlyAmount] = useState(0);
  const [monthlyAmount, setMonthlyAmount] = useState(0);
  const [dailyAmount, setDailyAmount] = useState(0);

  useEffect(() => {
    calculateAmounts(rangeValue);
    updateSliderBackground(rangeValue);
  }, [rangeValue]);

  const calculateAmounts = (value) => {
    const yearly = value * 0.09 + 50;
    const monthly = yearly / 9;
    const daily = monthly / 20;

    setYearlyAmount(yearly.toFixed(2));
    setMonthlyAmount(monthly.toFixed(2));
    setDailyAmount(daily.toFixed(2));
  };

  const updateSliderBackground = (value) => {
    const percentage = ((value - 30000) / 120000) * 100;
    const slider = document.getElementById('rangeSlider');
    slider.style.background = `linear-gradient(to right, #4CAF50 ${percentage}%, #ddd ${percentage}%)`;
  };

  const handleRangeChange = (event) => {
    const value = parseInt(event.target.value);
    setRangeValue(value);
    updateSliderBackground(value);
  };

  return (
    <Container>
      <Row className="my-4">
        <Col className="text-center">
          <div className="range-slider">
            <input
              id="rangeSlider"
              type="range"
              min="30000"
              max="150000"
              step="10000"
              value={rangeValue}
              onChange={handleRangeChange}
              className="form-range"
              style={{ width: "100%" }}
            />
            <div className="range-value" style={{ left: `${((rangeValue - 30000) / 120000) * 100}%` }}>
              {rangeValue}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <span>30000</span>
            <span>150000</span>
          </div>
          <p>Selected Coverage: {rangeValue}</p>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mb-3">
          <h5>Yearly</h5>
          <Button variant="success">Rs. {yearlyAmount}</Button>
        </Col>
        <Col className="text-center mb-3">
          <h5>Monthly</h5>
          <Button variant="secondary">Rs. {monthlyAmount}</Button>
        </Col>
        <Col className="text-center mb-3">
          <h5>Daily</h5>
          <Button variant="info">Rs. {dailyAmount}</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default StepRangeSlider;







// import React, { useState, useEffect } from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

// const StepRangeSlider = () => {
//   const [rangeValue, setRangeValue] = useState(30000);
//   const [yearlyAmount, setYearlyAmount] = useState(0);
//   const [monthlyAmount, setMonthlyAmount] = useState(0);
//   const [dailyAmount, setDailyAmount] = useState(0);

//   useEffect(() => {
//     calculateAmounts(rangeValue);
//   }, [rangeValue]);

//   const calculateAmounts = (value) => {
//     const yearly = value * 0.09 + 50;
//     const monthly = (value * 0.09) / 9;
//     const daily = (value * 0.09) / 9 / 20;

//     setYearlyAmount(yearly.toFixed(2));
//     setMonthlyAmount(monthly.toFixed(2));
//     setDailyAmount(daily.toFixed(2));
//   };

//   const handleRangeChange = (event) => {
//     setRangeValue(parseInt(event.target.value));
//   };

//   const callApi = async (id, amount, coverage) => {
//     const payload = {
//       id,
//       amount,
//       coverage,
//     };
//     console.log(payload);
//     try {
//       const response = await fetch('https://your-api-endpoint.com/api/coverage', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(payload),
//       });
//       const data = await response.json();
//       console.log('API Response:', data);
//     } catch (error) {
//       console.error('Error calling API:', error);
//     }
//   };

//   const handleButtonClick = (id, amount) => {
//     callApi(id, amount, rangeValue);
//   };

//   return (
//     <Container>
//       <Row className="my-4">
//         <Col className="text-center">
//           <input
//             type="range"
//             min="30000"
//             max="150000"
//             step="10000"
//             value={rangeValue}
//             onChange={handleRangeChange}
//             className="form-range"
//             style={{ width: "100%" }}
//           />
//           <div className="d-flex justify-content-between">
//             <span>30000</span>
//             <span>150000</span>
//           </div>
//           <p>Selected Coverage: {rangeValue}</p>
//         </Col>
//       </Row>
//       <Row>
//         <Col className="text-center mb-3">
//           <h5>Yearly</h5>
//           <Button variant="success" onClick={() => handleButtonClick(192, yearlyAmount)}>
//             Rs. {yearlyAmount}
//           </Button>
//         </Col>
//         <Col className="text-center mb-3">
//           <h5>Monthly</h5>
//           <Button variant="secondary" onClick={() => handleButtonClick(189, monthlyAmount)}>
//             Rs. {monthlyAmount}
//           </Button>
//         </Col>
//         <Col className="text-center mb-3">
//           <h5>Daily</h5>
//           <Button variant="info" onClick={() => handleButtonClick(186, dailyAmount)}>
//             Rs. {dailyAmount}
//           </Button>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default StepRangeSlider;

