// src/components/Icon.js
import React from "react";
// import '.././style.css';
const Icon = ({ icon, label }) => {
  return (
    <div className="text-center">
         {/* <div className="icon-container"> */}
      <img src={icon} alt={label} className="mb-2" style={{ width: "45px" }} />
      {/* </div> */}
      <p style={{ fontWeight: "700", fontSize:'10px' }}>{label}</p>
    </div>
  );
};

export default Icon;
