class car_packages {
  constructor() {
    this.items = [
      // {
      //   id: 34,
      //   image: "assets/MiniAppAssets/car.png",
      //   name: "Road Side Assistance",
      //   badge: "Silver Card Monthly",
      //   coverage: "",
      //   price: "1500",
      //   bgColorClass: "bg-info",
      //   description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //   `,
      //   type: "rsa",
      // },
      // {
      //   id: 35,
      //   image: "assets/MiniAppAssets/car.png",
      //   name: "Road Side Assistance",
      //   badge: "Gold Card Monthly",
      //   coverage: "",
      //   price: "2500",
      //   bgColorClass: "bg-warning",
      //   description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //   `,
      //   type: "rsa",
      // },
      // {
      //     id: 36,
      //     image: "assets/MiniAppAssets/car.png",
      //     name: "Road Side Assistance",
      //     badge: "Platinum Card Monthly",
      //     coverage: "200,000",
      //     price: "3500",
      //     bgColorClass: "bg-info",
      //     description: `In Islamabad and Rawalpindi, we offer 24/7 towing services and provide up to Rs. 100,000 for motor accident maintenance and accidental ICU & IPD hospitalization.
      //     `,
      //     type: "rsa",
      //   },
      //   {
      //     id: 31,
      //     image: "assets/MiniAppAssets/car.png",
      //     name: "Road Side Assistance",
      //     badge: "Silver Card Annual",
      //     coverage: "",
      //     price: "9000",
      //     bgColorClass: "bg-warning",
      //     description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //     `,
      //     type: "rsa",
      //   },
      //   {
      //     id: 32,
      //     image: "assets/MiniAppAssets/car.png",
      //     name: "Road Side Assistance",
      //     badge: "Gold Card Annual",
      //     coverage: "",
      //     price: "18000",
      //     bgColorClass: "bg-info",
      //     description: `This includes towing of the vehicle, and 24/7 roadside assistance in Islamabad/Rawalpindi.
      //     `,
      //     type: "rsa",
      //   },
      //   {
      //     id: 33,
      //     image: "assets/MiniAppAssets/car.png",
      //     name: "Road Side Assistance",
      //     badge: "Platinum Card Annual",
      //     coverage: "350,000",
      //     price: "30000",
      //     bgColorClass: "bg-warning",
      //     description: `In Islamabad and Rawalpindi, we offer 24/7 towing services and provide up to Rs. 100,000 for motor accident maintenance and Rs. 200,000 for accidental ICU & IPD hospitalization.
      //     `,
      //     type: "rsa",
      //   },
//       {
//         id: 30,
//         image: "assets/MiniAppAssets/car.png",
//         name: "Car Insurance",
//         badge: "Monthly",
//         coverage: "200,000",
//         price: "1500",
//         bgColorClass: "bg-info",
//         description: `<p><strong>Rapid Insurance Setup:</strong> Secure your car with quick and simple online registration.</p>
// <ul>
// <li><strong>Total Insurance Cover: </strong>PKR 200,000</li>
// <li><strong>Accidental Car Repair: </strong>Up to PKR 100,000</li>
// <li><strong>Accidental Hospitalization: </strong>Up to PKR 100,000</li>
// <li><strong>RSA Coverage:</strong> Available for Rawalpindi/Islamabad</li>
// </ul>
// <p><strong>Simple Claims, Superior Support:</strong> Our dedicated team makes claims processing quick and straightforward.</p>
// <p><strong>Protect your vehicle and health with our all-inclusive insurance plan!</strong></p>`,
//       },
      {
        id: 178,
        image: "assets/MiniAppAssets/car.png",
        name: "Car Insurance",
        badge: "Annual",
        coverage: "350,000",
        price: "15000",
        bgColorClass: "bg-warning",
        description: `<p><strong>Rapid Insurance Setup:</strong> Secure your car with quick and simple online registration.</p>
<ul>
<li><strong>Total Insurance Cover: </strong>PKR 350,000</li>
<li><strong>Accidental Car Repair: </strong>Up to PKR 150,000</li>
<li><strong>Accidental Hospitalization: </strong>Up to PKR 200,000</li>
<li><strong>RSA Coverage: </strong>Available for Rawalpindi/Islamabad</li>
</ul>
<p><strong>Simple Claims, Superior Support:</strong> Our dedicated team makes claims processing quick and straightforward.</p>
<p><strong>Protect your vehicle and health with our all-inclusive insurance plan!</strong></p>`,
        type: "insurance",
      },
    ];

    this.terms = `
    <p><strong>Coverage Limitations</strong></p>
    <ul>
      <li>Fire, loss, and theft are excluded from coverage.</li>
      <li>Coverage is applicable only in the event of a Road Traffic Accident (RTA), as defined by the terms of this policy.</li>
    </ul>
    
    <p><strong>Eligibility Criteria</strong></p>
    <ul>
      <li>The policyholder must be a licensed holder.</li>
      <li>The policyholder must be the owner of the insured car.</li>
      <li>Age of the policyholder must be between 18 and 59 years.</li>
      <li>The insured car must be used solely for domestic or transportation purposes, excluding involvement in racing or other activities.</li>
    </ul>
    
    <p><strong>Exclusions</strong></p>
    <ul>
      <li>Excluded perils include war, strikes, riots, civil commotion, and any natural or man-made disasters leading to a declaration of a state of emergency on a local or national level.</li>
      <li>Coverage does not extend to preexisting conditions (accidents/damages/hospitalization) of the insured car and insurer.</li>
      <li>Services related to vehicle modification or customization are not covered.</li>
      <li>Routine maintenance services such as oil changes, tire rotations, or battery replacements are excluded.</li>
      <li>Vehicles used for commercial purposes, such as Bykea or Careem, etc., are not covered unless specifically included in the policy.</li>
      <li>Services performed at repair shops or dealerships, including repairs or maintenance, are excluded unless specifically covered in the policy.</li>
    </ul>
    
    <p><strong>Claims Process</strong></p>
    <ul>
      <li>All claims must be filed via Whatsapp within a maximum of 15 days from the date of the Road Traffic Accident (RTA).</li>
      <li>The following documents are required to raise a claim:</li>
      <ul>
        1. Registration Book of the insured car.<br />
        2. Police report documenting the RTA.<br />
        4. Valid license of the policyholder.<br />
        5. National Identity Card (CNIC) of the policyholder.<br />
        6. Repair receipts where applicable.<br />
        7. Hospital admission, bills, discharge slip, and emergency bills in case of RTA-related injuries.</li>
      </ul>
      <li>Upon completion of the required documentation, claims will be processed and paid within 15 days.</li>
    </ul>
  `;
  }

  getAllItems() {
    return this.items;
  }

  getTerms() {
    return this.terms;
  }
}

export default car_packages;
