import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyACYHTB68p1CBv1pW0MJSM2liB76uWBSOw",
  authDomain: "webdoc-896a8.firebaseapp.com",
  databaseURL: "https://webdoc-896a8.firebaseio.com",
  projectId: "webdoc-896a8",
  storageBucket: "webdoc-896a8.appspot.com",
  messagingSenderId: "642677587502",
  appId: "1:642677587502:web:b44f84172056b7971a69bf",
  measurementId: "G-JTMSYGQBYX"
};

const app = initializeApp(firebaseConfig);  
const database = getDatabase(app);
export {database}